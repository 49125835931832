import axios from "@/config/http";
export const factoryApi = {
    getBaseData(params) {
        return axios.get("/inquiry/factory/page", { params });
    },
    addFactoryQuery(data) {
        return axios.post('/inquiry/factory', data)
    },
    editFactoryQuery(data) {
        return axios.put('/inquiry/factory', data)
    },
    // 获取工厂详情/inquiry/factory/detail
    getfactdetail(params) {
        return axios.get('/inquiry/factory/detail', { params })
    },
    // 新增验厂信息
    createInspection(data){
        return axios.post('/inquiry/factory/createInspection',data)
    },
    // 查看验厂配置
    inspectionDetail(params){
        return axios.get('/inquiry/factory/inspectionDetail',{params})
    }
}
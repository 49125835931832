// import { reject } from "core-js/fn/promise";
import axios from "axios";
import store from "@/store";
import * as ObsClient from "../../public/esdk-obs-browserjs-without-polyfill.3.22.3.min.js"
/**
 *
 * @param url 项目接口地址
 * @name 获取obs临时密钥
 * @returns 密钥
 * 需要安装插件 axios
 */
async function getAkAndSk(url) {
  let obj = {};
  await axios
    .get(url + "/front-api/obs/akAndSk", {
      headers: {
        Authorization: store.state.Login?.token
          ? "Bearer " + store.state.Login.token
          : "",
      },
    })
    .then((res) => {
      obj = res.data.data;
    })
    .catch((error) => {
      console.error(error);
    });
  return obj;
}
/**
 * 简单实现防抖方法
 *
 * @name obs文件上传
 * @param file 上传的文件，必填
 * @param url 项目接口地址
 * @returns 文件线上访问地址
 * 需要安装插件 moment.js  js-md5  华为云BrowserJS的SDK
 */
export async function initObsClient(url, file) {
  //时间格式化插件
  let moment = require("moment");
  //md5加密插件
  const md5 = require("js-md5");
  const time = moment().format("YYYY/MM/DD") + "/";
  const md5File = md5(String(Date.now()));
  const fileName =
    time + md5File + "." + file.name.substring(file.name.lastIndexOf(".") + 1);
  try {
    // OBS BrowserJS SDK，不支持npm引入,需手动引入项目,相对路径自己配置
    // const ObsClient = require("../../public/esdk-obs-browserjs-without-polyfill.3.22.3.min.js");
    // console.log("fdasfdsfsd", ObsClient);
    //获取临时密钥
    const sk = await getAkAndSk(url);
    const client = new ObsClient({
      security_token: sk.securitytoken, //临时securityToken
      access_key_id: sk.access, //ak+
      secret_access_key: sk.secret, //sk
      server: "obs.cn-east-3.myhuaweicloud.com", //server地址
    });
    //发送请求
    return new Promise((reslove) => {
      client.putObject(
        {
          Bucket: "linkdistance", //桶名称
          Key: fileName, //上传文件名
          SourceFile: file, //文件
        },
        //上传回调
        function (err, result) {
          if (err) {
            // console.error("Error-->" + err);
            return reslove(false);
          } else {
            // console.log("Status-->" + result.CommonMsg.Status);
            if (result.CommonMsg.Status === 200) {
              reslove({
                fileAddress:
                  "https://linkdistance.obs.cn-east-3.myhuaweicloud.com/" +
                  fileName,
                fileName: fileName,
              });
            }
          }
        }
      );
    });
  } catch (e) {
    console.log(e);
    return false;
  }
}

<template>
  <div>
    <a-form-model :model="form" :rules="rules" ref="form">
      <a-form-model-item
        class="alignment"
        label="商品认证 "
        prop="productQualification"
      >
        <a-checkbox-group
          v-model="form.productQualification"
          class="alignmentArea"
          :disabled="inputdisabled"
          @change="handleBlur('productQualification')"
        >
          <a-checkbox
            class="alignmentArea-item"
            v-for="(item, index) in salesArea"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-checkbox
          >
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item
        class="alignment imagepos"
        label="商品认证报告 "
        prop="qualificationImages"
      >
        <p
          style="color: rgba(51, 51, 51, 0.65); height: 21px; line-height: 15px"
        >
          支持.jpg .png 格式，最多不超过21张
        </p>
        <uploadImage
          :disabled="inputdisabled"
          :config="imageConfig"
          v-model="form.qualificationImages"
          @blur="handleBlur('qualificationImages')"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
import uploadImage from "@/components/uploadImage";
// import { SelectionGoods } from "@/api/SelectionGoods.js";
export default {
  name: "authentication",
  // inject: ["editData"],
  props: {
    editData: {
      type: Object,
    },
  },
  data() {
    return {
      rules,
      form: {
        productQualification: [],
        qualificationImages: [],
      },
      salesArea: [
        {
          label: "CCC",
          value: "CCC",
        },
        {
          label: "ISO",
          value: "ISO",
        },
        {
          label: "NCC",
          value: "NCC",
        },
        {
          label: "UL",
          value: "UL",
        },
        {
          label: "ETL",
          value: "ETL",
        },

        {
          label: "GS",
          value: "GS",
        },
        {
          label: "FCC",
          value: "FCC",
        },
        {
          label: "CE",
          value: "CE",
        },
        {
          label: "ROHS",
          value: "ROHS",
        },
        {
          label: "HACCP",
          value: "HACCP",
        },

        {
          label: "CB",
          value: "CB",
        },
        {
          label: "BSI",
          value: "BSI",
        },
        {
          label: "PSE",
          value: "PSE",
        },
        {
          label: "SOSA",
          value: "SOSA",
        },
        {
          label: "CU-TR",
          value: "CU-TR",
        },
        {
          label: "SAA",
          value: "SAA",
        },
        {
          label: "KC",
          value: "KC",
        },
        {
          label: "SNI",
          value: "SNI",
        },
        {
          label: "ICASA",
          value: "ICASA",
        },
        {
          label: "CSA",
          value: "CSA",
        },
        {
          label: "NF",
          value: "NF",
        },
        {
          label: "定制认证",
          value: "定制认证",
        },
      ],
      imageConfig: {
        maxLength: 21,
        maxSize: 10,
      },
    };
  },
  components: {
    uploadImage,
  },
  methods: {
    validateForm() {
      return new Promise((resolve) => {
        this.$refs.form.validate((isok) => {
          console.log(isok);
          return resolve(isok);
        });
      });
    },
    handleBlur(data) {
      this.$refs.form.validateField(data);
    },
  },
  created() {
    if (this.editData?.data) {
      let target = this.editData.data;
      this.form.productQualification = target.productQualification.split(",");
      this.form.qualificationImages =
        target.qualificationImages === null ? [] : target.qualificationImages;
    }
  },
  computed: {
    inputdisabled() {
      return this.editData.type === "uninput";
    },
  },
};
</script>

<style scoped lang='scss'>
.alignment {
  display: flex;
  .ant-col {
    width: 100px;
    label {
      white-space: initial;
    }
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
}
.alignmentArea {
  display: inline-flex;
  flex-wrap: wrap;
  .alignmentArea-item {
    width: 16%;
    margin-left: 0px;
  }
}
.imagepos {
  label {
    top: -11px;
  }
}
</style>
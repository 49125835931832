import axios from "@/config/http";
export const shelfApplicaAPi = {
  getBaseData(params) {
    return axios.get("/mall/factory/getAddGoodsList", { params });
  },
  // 新增数据
  addGoodData(data) {
    return axios.post("/mall/factory/putaway", data);
  },
  // 编辑数据
  editGoodData(data) {
    return axios.put("/mall/factory/updateGoods", data);
  },
  // 获取编辑数据
  geyEditData(params) {
    return axios.get("/mall/factory/getOrderBySku", { params });
  },
  getDetail(tenantId) {
    return axios(`/mall/factory/detailFactory/${tenantId}`);
  },
  // 暂存数据
  temporaryData(params) {
    return axios.post("/mall/factory/temporary", params);
  },

  // 处理选品上架里面的一键导出
  exportGoods(params) {
    return axios.post(`mall/factory/goodsExport`, params, {
      responseType: "blob",
    });
  },
};

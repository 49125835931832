<template>
  <div>
    <a-input
      @blur="handleBlur"
      :value="value"
      placeholder="请输入"
      v-bind="$attrs"
      ref="input"
      @change="handleChange"
      @keyup="handelkeyup"
    >
    </a-input>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: undefined,
    },
    config: {
      type: Object,
      default() {
        return {
          reg: /^[1-9]\d*$/,
          msg: "请输入最小值为1的正整数",
          type: undefined,
        };
      },
    },
  },
  methods: {
    handleBlur(e) {
      if (isNaN(e.target.value) && !this.config.type) {
        this.$emit("input", this.value);
        return this.$message.error("值输入错误");
      }
      let target = e.target.value;
      if (!this.config.reg.test(target)) {
        this.$emit("input", this.value);
        this.$emit("blur");
        return this.$message.error(this.config.msg);
      }
      console.log(target, "targettargettarget");
      this.$emit("input", target);
      this.$emit("blur");
    },
    focus() {
      this.$refs.input.focus();
    },
    handleChange(e) {
      this.$emit("chnage", e);
    },
    handelkeyup(e) {
      this.$emit("keyup", e);
    },
  },
};
</script>

<style>
</style>
import message from 'ant-design-vue/es/message'
import axios from '@/config/http.js'
let confirm = require('ant-design-vue/lib/modal')['default']['confirm']
import Vue from 'vue'
import { Spin } from "ant-design-vue";
function deleteApi(data) {
  // 删除url-----xxx/id
  return axios.delete(data)
}

// 消息提示
export function tips(res, name) {
  if (res.success) {
    message.success(`${name}成功!`);
  } else {
    message.error(res.errorMsg);
  }
}


function getInstanceVue() {
  let instance = new Vue({
    render: h => h('div', {
      style: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 99999
      }
    }, [
      h(Spin, {
        style: {
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)'
        },
        attrs: {
          'size': "large"
        }
      })
    ]),
  }).$mount()
  return instance.$el
}
// 加载效果
export class Loading {
  constructor() {
    this.target = getInstanceVue()
  }
  show() {
    document.body.appendChild(this.target)
  }
  close() {
    document.body.removeChild(this.target)
  }
}
export const loading = new Loading()
// 删除模态框
export function deleteshow(url, callback ,isLoading = true , title = '是否确定删除此数据') {
  // let content = "删除后不可恢复"
  confirm({
    title,
    // content,
    cancelText: "取消",
    okText: "确认",
    onOk: () => {
      isLoading && loading.show()
      deleteApi(url)
        .then((res) => {
          loading.close()
          tips(res, "删除");
          if (res.success) {
            callback && callback()
          }
        })
        .catch((err) => {
          isLoading && loading.close()
          message.error("出错了");
          console.log(err);
        });
    },
  });
}
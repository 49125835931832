
<template>
  <div>
    <template v-for="(tag, index) in tags">
      <a-tag
        :key="tag.key"
        :closable="!disabled"
        @close="() => handleClose(index)"
        >{{ tag.value }}</a-tag
      >
    </template>
    <span v-if="tags.length != max">
      <numberInput
        v-if="inputVisible"
        ref="input"
        type="text"
        size="small"
        :config="skuColor"
        :style="TgOptions.style"
        :value="inputValue"
        :placeholder="TgOptions.placeholder"
        v-model="inputValue"
        @blur="(e) => handleInputConfirm(e, 'blur')"
        @keyup.enter.stop="(e) => handleInputConfirm(e, 'keyup')"
      />
      <span v-else>
        <a-tag
          v-show="!disabled"
          style="background: #fff; borderstyle: dashed"
          @click="showInput"
        >
          <a-icon type="plus" />
          {{ TgOptions.title }}
        </a-tag>
      </span>
    </span>
    <p class="tips">标签最多存在四个，每个标签最多四个字符</p>
  </div>
</template>

<script>
import numberInput from "@/components/numberInput";
export default {
  model: {
    prop: "value",
  },
  name: "inputTag",
  data() {
    return {
      tags: [],
      inputVisible: false,
      inputValue: "",
      data: [],
      skuColor: {
        reg: /^[A-Za-z\u4e00-\u9fa5]{1,4}$/,
        msg: "标签名只能是最长为四位的中文或者英文",
        type: "String",
      },
    };
  },
  components: {
    numberInput,
  },
  mounted() {
    // 回显赋值需要
    let resultArr = this.value ? this.value.split("/") : [];
    resultArr = resultArr.map((v) => {
      return {
        value: v,
        key: +new Date() + "" + Math.floor(Math.random() * 99),
      };
    });
    this.tags = resultArr;
  },
  props: {
    value: {
      type: String,
    },
    max: {
      type: Number,
      default: 4,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    TgOptions: {
      type: Object,
      default: function () {
        return {
          title: "添加tags", // 添加title,
          placeholder: "请输入",
          style: {
            // input style
            width: "180px",
          },
          tagStyle: {},
        };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dataChange() {
      let target = this.tags.map((v) => v.value);
      this.$emit("input", target.join("/"));
      this.$emit("blur");
    },
    handleClose(data) {
      this.tags = this.tags.filter((v, index) => {
        return index != data;
      });
      this.dataChange();
    },
    showInput() {
      if (this.disabled) return;
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
    handleInputConfirm() {
      this.inputVisible = false;
      if (this.inputValue !== "") {
        setTimeout(() => {
          if (this.inputValue === "") return;
          let obj = {
            value: this.inputValue,
            key: +new Date(),
          };
          this.tags.push(obj);
          this.inputValue = "";
          this.inputVisible = false;
          this.dataChange();
        }, 0);
      }
    },
  },
};
</script>

<style scoped>
.tips {
  height: 20px;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.65);
  line-height: 10px;
}
</style>


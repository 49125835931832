import axios from "@/config/http";
export const SelectionGoods = {
  getBaseData(params) {
    return axios.get("/mall/factory/getGoodsList", { params });
  },
  getEditData(params) {
    return axios.get('/mall/factory/getOrderBySku', { params })
  },
  getCateGoryData() {
    return axios.get('/mall/factory/getGoodsCategory')
  },
  // 待审核数据审核
  examineData(data) {
    return axios.put('/mall/factory/audit/', data)
  },
  // 获取可售区域/mall/factory/listDetailByName   http://192.168.233.168:8086/mall/factory/listDetailByName/
  getlistDetailByName(name) {
    return axios.get('/mall/factory/listDetailByName/' + name)
  },
  // 获取工厂信息
  getDropdownFactory() {
    return axios.get('mall/factory/dropdownFactory')
  }
}

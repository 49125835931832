export default {
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    //校验对象
    checkParams(params) {
      let flag = true;
      for (let key in params) {
        if (!params[key] || params[key].length === 0) {
          this.$message.error("请完善所有带 * 的必填项！");
          return false;
        }
      }
      return flag;
    },
    //对话框
    showConfirm(func, title = "删除确认", content = "是否确认删除此记录？") {
      this.$confirm({
        title,
        content,
        cancelText: "取消",
        okText: "确认",
        onOk() {
          func();
        },
        onCancel() {
          console.log("取消删除");
        },
      });
    },
    // 弹出框取消按钮
    cancel() {
      this.$parent.closeModal();
    },
    // 克隆对象
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    // 删除表格项
    onDelete(sort, id, i) {
      const dataSource = [...this.data];
      this.data = dataSource.filter((item, index) => index !== i);
      this.count = this.count - 1;
    },
    // 处理表格输入
    handleInputChange(value, key) {
      this.data.forEach((item) => {
        if (key === item.key) {
          item.note = value;
        }
      });
    },
    // 处理antd下拉框错位
    handleDislocation(triggerNode) {
      return triggerNode.parentNode || document.body;
    },
    // 处理输入框清除之后重新调接口
    queryClose(val) {
      console.log(val.target.value);
      if (val.target.value === "") {
        this.pagination.current = 1;
        this.getSourceData();
      }
    },
    // 表格拖拽
    customRow(record, index) {
      return {
        style: {
          cursor: "pointer",
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            var ev = event || window.event; // 兼容IE
            ev.target.draggable = true;
          },
          mousedown: (event) => {
            var ev = event || window.event;
            console.log(ev.target);
          },
          // 开始拖拽
          dragstart: (event) => {
            var ev = event || window.event;
            ev.stopPropagation(); // 阻止冒泡
            this.sourceObj = record; // 得到源目标数据
            this.sourceIndex = index;
          },
          // 拖动元素经过的元素
          dragover: (event) => {
            // 兼容 IE
            var ev = event || window.event;
            // 阻止默认行为
            ev.preventDefault();
          },
          // 鼠标松开
          drop: (event) => {
            var ev = event || window.event;
            ev.stopPropagation();
            this.targetObj = record;
            this.targetIndex = index;
            console.log(this.sourceObj);
            let sourceObj = JSON.parse(JSON.stringify(this.sourceObj)); //备份要移动的行
            this.data.splice(this.sourceIndex, 1); //删除已备份的源数据行
            this.data.splice(this.targetIndex, 0, sourceObj); //在目标行插入备份行
            this.data.map((item, index) => {
              //重新排序
              item.stepOrder = index + 1;
            });
          },
        },
      };
    },
  },
};

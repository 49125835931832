<template>
  <div>
    <div style="text-align: right">
      <a-button @click="addTableRow" v-if="!inputdisabled" class="xp-btn-color">
        新增
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="data" :pagination="false">
      <template slot="picture" slot-scope="text, record">
        <div @click=clickTimes(record)>
          <uploadImage
          :disabled="inputdisabled"
          :config="uploadConfig"
          v-model="record.skuImages"
          :key="record.goodsId"
       
        />
        </div>
      </template>
      <template slot="skuType" slot-scope="text, record">
        <div @click=clickTimes(record)>
          <numberInput
            :disabled="inputdisabled"
            :config="skuModel"
            v-model="record.skuType"
            
          />
        </div>
      </template>
      <template slot="skuColor" slot-scope="text, record">
        <div @click=clickTimes(record)>
          <numberInput
            :disabled="inputdisabled"
            :config="skuColor"
            v-model="record.skuColor"
            @click=clickTimes(record)
          />
        </div>
      </template>
      <template slot="onPrice" slot-scope="text, record">
        <div>
          <a-input-number
            v-model="record.onPrice"
            :disabled="inputdisabled"
            style="width: 100%"
            :min="0.01"
            :precision="2"
            placeholder="请输入"
            @click=clickTimes(record)
          ></a-input-number>
        </div>
      </template>
      <template slot="supplyPrice" slot-scope="text, record">
        <div>
          <a-input-number
            v-model="record.supplyPrice"
            :disabled="inputdisabled"
            style="width: 100%"
            :min="0.01"
            :precision="2"
            placeholder="请输入"
            @click=clickTimes(record)
          ></a-input-number>
        </div>
      </template>
      <template
        slot="stock"
        slot-scope="text, record"
        v-if="columns[5].key === 'stock'"
      >
        <a-switch v-model="record.stock"  @change="isclick(record)"/>
      </template>
      <template slot="option" slot-scope="text, record">
        <div
          style="color: #ff4600; cursor: pointer"
          @click="deletatable(record)"
        >
          删除
        </div>
      </template>
      <!-- 自定义表格头 -->
      <span slot="customTitle"><span style="color: red"> * </span> 图片</span>
      <span slot="modelTitle"><span style="color: red"> * </span> 型号</span>
      <span slot="colorTitle"><span style="color: red"> * </span> 颜色</span>
      <span slot="PriceTitle"
        ><span style="color: red"> * </span> 基础价(元)</span
      >
      <span slot="supplyPriceTitle"
        ><span style="color: red"> * </span> 工厂价(元)</span
      >
      <span slot="stock" v-if="!editData.data">库存处理</span>
    </a-table>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "skuImages",
    key: "skuImages",
    slots: { title: "customTitle" },
    scopedSlots: { customRender: "picture" },
  },
  {
    slots: { title: "modelTitle" },
    scopedSlots: { customRender: "skuType" },
    dataIndex: "skuType",
    key: "skuType",
  },
  {
    slots: { title: "colorTitle" },
    scopedSlots: { customRender: "skuColor" },
    dataIndex: "skuColor",
    key: "skuColor",
  },
  {
    slots: { title: "PriceTitle" },
    scopedSlots: { customRender: "onPrice" },
    key: "onPrice",
    dataIndex: "onPrice",
  },
  {
    slots: { title: "supplyPriceTitle" },
    scopedSlots: { customRender: "supplyPrice" },
    key: "supplyPrice",
    dataIndex: "supplyPrice",
  },
  {
    title: "操作",
    key: "action",
    width: 100,
    scopedSlots: { customRender: "option" },
  },
];

const data = [
  {
    key: "1",
    skuType: "",
    skuColor: "绿色",
    onPrice: "",
    skuImages: "",
  },
];
import uploadImage from "@/components/uploadImage";
import numberInput from "@/components/numberInput";
import { setKey } from "@/utils/utils.js";
export default {
  name: "skuIdskuIdskuId",
  // inject: ["editData"],
  props: {
    editData: {
      type: Object,
    },
  },
  data() {
    return {
      data,
      columns,
      uploadConfig: {
        maxLength: 1,
        maxSize: 10,
      },
      skuColor: {
        reg: /^.{1,16}$/,
        msg: "请输入1到16位字符",
        type: "String",
      },
      skuModel: {
        reg: /^.{1,64}$/,
        msg: "请输入1到64位字符",
        type: "String",
      },
      numberConfig: {
        reg: /^(([1-9]\d*)(\.\d+)?)$|^0\.\d*[1-9]$/,
        msg: "请输入不包含零的正数",
      },
    };
  },
  components: {
    uploadImage,
    numberInput,
  },
  methods: {
    addTableRow() {
      if (this.inputdisabled) return;
      let data = {
        key: +new Date(),
        skuType: "",
        skuColor: "",
        onPrice: "",
        skuImages: "",
        times:0
      };
      this.data.push(data);
    },
    deletatable(record) {
      if (this.data.length === 1 || this.inputdisabled) return;
      this.data = this.data.filter((v) => v.key !== record.key);
    },
    validateForm() {
      return new Promise((resolve) => {
        let target = this.data.every((v) => {
          return v.skuImages.length && v.skuType && v.skuColor && v.onPrice;
        });
        // console.log(target, "dasdas", this.data);
        resolve(target);
      });
    },
    isclick(record){
     if(record.stock&&record.times===1){
      this.$emit("setSKU", record);
     }
    },
    handleBlur(data, e) {
      if (isNaN(e.target.getAttribute("aria-valuenow"))) {
        return this.$message.error("值输入错误");
      }
      if (Math.floor(e.target.getAttribute("aria-valuenow")) <= 0) {
        data.onPrice = 1;
        return this.$message.error("值输入最小值为1");
      }
      data.onPrice = Math.floor(e.target.getAttribute("aria-valuenow"));
    },
    clickTimes(record){
    //  console.log(record);
   
     if(!record.stock){
      record.times=0
     }else{
      record.times=2
     }
     if(record.times===0){
      record.times=1
     }else{
      record.times=2
     }
    }
  },
  created() {
    if (this.editData?.data) {
      if (this.columns[5]?.key === "stock") {
        this.columns.splice(5, 1);
      }
      let target = setKey(this.editData.data.skuvos, "skuId");
      this.data = target;
    } else {
      this.data = [
        {
          key: +new Date(),
          skuType: "",
          skuColor: "",
          onPrice: "",
          skuImages: "",
          times:0
        },
      ];
      if (this.columns[5]?.key !== "stock") {
        this.columns.splice(5, 0, {
          slots: { title: "stock" },
          scopedSlots: { customRender: "stock" },
          key: "stock",
          dataIndex: "stock",
          width: 100,
        });
      }
    }
  },
  computed: {
    inputdisabled() {
      return this.editData.type === "uninput";
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.avatar-uploader > .ant-upload {
  width: 80px;
  height: 80px;
}
/deep/.ant-upload-select-picture-card i {
  font-size: 22px;
}
/deep/.ant-upload-select-picture-card .ant-upload-text {
  font-size: 8px;
}
.ant-table-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  /deep/.ant-table-thead > tr > th {
    background-color: #ffe9db;
  }
}
</style>

<template>
  <div>
    <a-modal
      :title="config.type === 'edit' ? '编辑' : '新增'"
      :visible="config.visible"
      @cancel="handleCancel"
      width="900px"
      @ok="handleOk"
      ok-text="提交"
      cancel-text="返回"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item label="工厂名称" prop="factoryName">
          <a-input
            placeholder="请输入"
            v-model="form.factoryName"
            :disabled="config.type === 'edit'"
          />
        </a-form-model-item>
        <a-form-model-item label="统一社会信用代码：" prop="factoryCreditCode">
          <a-input
            placeholder="请输入"
            v-model="form.factoryCreditCode"
            :disabled="config.type === 'edit'"
          />
        </a-form-model-item>
        <a-form-model-item label="工厂地址" prop="factoryAddress">
          <a-input placeholder="请输入" v-model="form.factoryAddress" />
        </a-form-model-item>
        <a-form-model-item label="工厂性质">
          <a-select
            v-model="form.factoryType"
            style="width: 100%"
            :getPopupContainer="(triggerNode) => handleDislocation(triggerNode)"
            placeholder="请选择"
          >
            <a-select-option
              v-for="(item, index) in factory"
              :key="index"
              :value="item.value"
              >{{ item.lable }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="经营类型">
          <a-select
            v-model="form.manageType"
            style="width: 100%"
            :getPopupContainer="(triggerNode) => handleDislocation(triggerNode)"
            placeholder="请选择"
          >
            <a-select-option
              v-for="(item, index) in management"
              :key="index"
              :value="item.value"
              >{{ item.lable }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="厂房房属权">
          <a-select
            v-model="form.escheatage"
            style="width: 100%"
            :getPopupContainer="(triggerNode) => handleDislocation(triggerNode)"
            placeholder="请选择"
          >
            <a-select-option
              v-for="(item, index) in Ownership"
              :key="index"
              :value="item.value"
              >{{ item.lable }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="经营范围" prop="manageScope">
          <a-input placeholder="请输入" v-model="form.manageScope" />
        </a-form-model-item>
        <a-form-model-item label="厂房面积">
          <a-input-number
            placeholder="请输入"
            style="width: 417px"
            v-model="form.plantAcreage"
            :min="0.01"
            :precision="2"
          />
        </a-form-model-item>
        <a-form-model-item label="联系人" prop="factoryLinkman">
          <a-input placeholder="请输入" v-model="form.factoryLinkman" />
        </a-form-model-item>
        <a-form-model-item label="联系电话" prop="factoryPhonenumber">
          <a-input placeholder="请输入" v-model="form.factoryPhonenumber" />
        </a-form-model-item>
        <a-form-model-item label="营业执照" prop="factoryLicense">
          <UploadImage v-model="form.factoryLicense" :config="factoryConfig">
            <template #tip>
              <span style="opacity: 0.65">支持.jpg .png 格式</span>
            </template>
          </UploadImage>
        </a-form-model-item>
        <a-form-model-item label="工厂门面照片">
          <UploadImage v-model="form.facadePic" :config="factoryConfig">
            <template #tip>
              <span style="opacity: 0.65">支持.jpg .png 格式</span>
            </template>
          </UploadImage>
        </a-form-model-item>
        <a-form-model-item label="验厂报告">
          <UploadFile
            v-model="form.inspectionReport"
            :config="checkFactoryConfig"
          >
            <template #tip>
              <span style="opacity: 0.65"
                >支持格式：pdf、word ，单个文件不能超过50M</span
              >
            </template>
          </UploadFile>
        </a-form-model-item>
        <a-form-model-item label="是否支持一件代发" prop="ifsend">
          <a-radio-group v-model="form.ifsend">
            <a-radio :value="true"> 是 </a-radio>
            <a-radio :value="false"> 否 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- 绑定租户 -->
        <a-form-model-item label="租户编号">
          <a-input placeholder="请输入" v-model="form.tenantId" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
import { factoryApi } from "@/api/FactoryQuery.js";
import { tips } from "@/utils/tips.js";
import UploadImage from "@/components/uploadImage";
import UploadFile from "@/components/uploadFile";
import mixins from "@/mixins/mixins.js";
export default {
  mixins: [mixins],
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
        };
      },
    },
  },
  components: {
    UploadImage,
    UploadFile,
  },
  data() {
    return {
      form: {
        factoryName: null, //工厂名称
        factoryCreditCode: null, //统一社会信用代码：
        factoryAddress: null, //工厂地址
        manageScope: null, //经营范围
        factoryLinkman: null, //联系人
        factoryPhonenumber: null, //联系电话
        factoryLicense: "", //营业执照
        ifsend: false, //是否支持一件代发

        factoryType: undefined, // 工厂性质
        manageType: undefined, // 经营类型
        escheatage: undefined, // 厂房房属权
        plantAcreage: null, // 厂房面积
        facadePic: "", // 工厂门面照片
        inspectionReport: "", // 验厂报告
        tenantId: null, // 厘米os租户code
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      rules,
      factoryConfig: {
        maxLength: 1, //1张
        maxSize: 10, //20M
      },
      checkFactoryConfig: {
        maxLength: 1,
        maxSize: 50, //50M
      },
      factory: [
        {
          value: 0,
          lable: "上市",
        },
        {
          value: 1,
          lable: "民营",
        },
        {
          value: 2,
          lable: "国企",
        },
        {
          value: 3,
          lable: "外资",
        },
        {
          value: 4,
          lable: "合资",
        },
        {
          value: 5,
          lable: "其他",
        },
      ],
      management: [
        {
          value: 0,
          lable: "制造",
        },
        {
          value: 1,
          lable: "贸易",
        },
        {
          value: 2,
          lable: "工贸一体",
        },
      ],
      Ownership: [
        {
          value: 0,
          lable: "租用",
        },
        {
          value: 1,
          lable: "自购",
        },
      ],
    };
  },
  methods: {
    handleCancel() {
      this.config.visible = false;
    },
    handleOk() {
      this.$refs.ruleForm.validate(async (res) => {
        if (res) {
          if (this.config.type === "add") {
            let result = await factoryApi.addFactoryQuery(this.form);
            if (result.success) {
              this.handleCancel();
              // this.$parent.pagination.page = 1;
              this.$parent.resetData();
            }
            tips(result, "新增工厂");
          } else if (this.config.type === "edit") {
            this.form.factoryId = this.config.data.factoryId;
            let result = await factoryApi.editFactoryQuery(this.form);
            if (result.success) {
              this.handleCancel();
              // this.$parent.pagination.page = 1;
              this.$parent.resetData();
            }
            tips(result, "编辑工厂");
          }
        }
      });
    },
    async handleDeteil() {
      let data = this.config.data;

      for (const key in this.form) {
        this.form[key] = data[key];
      }

      if (
        this.form.factoryLicense === "" ||
        this.form.factoryLicense === null
      ) {
        this.form.factoryLicense = "";
      }
      if (this.form.facadePic === "" || this.form.facadePic === null) {
        this.form.facadePic = "";
      }
    },
  },
  created() {
    if (this.config.type === "edit") {
      this.handleDeteil();
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/.ant-form-horizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > div {
    width: 49%;
  }
}
</style>

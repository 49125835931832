<template>
  <div>
    <a-modal
      :title="config.title ? config.title : '新增'"
      :visible="config.visible"
      @cancel="handleCancel"
      :footer="null"
      width="900px"
    >
      <div>
        <div>
          <slot name="titleText"></slot>
        </div>
        <a-tabs v-model="selectPane">
          <a-tab-pane key="1" tab="商品概况">
            <goodDetailModel
              ref="formOne"
              :editData="config"
              @getConfig="handelConfig"
            />
          </a-tab-pane>
          <!-- 显示需要做判断 -->
          <a-tab-pane key="2" tab="SKU" force-render>
            <s-k-u-model ref="formTwo" :editData="config" @setSKU="getSKU" />
          </a-tab-pane>
          <!-- 显示需要做判断 -->
          <a-tab-pane key="4" tab="库存处理" force-render>
            <inventory-process
              ref="formFore"
              :editData="config"
              :inventoryInfo="inventoryInfo"
              :confing="goodsForm"
            />
          </a-tab-pane>
          <a-tab-pane key="3" tab="商品品质" force-render>
            <authentication ref="formThree" :editData="config" />
          </a-tab-pane>
        </a-tabs>
        <div>
          <slot name="footer"></slot>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import goodDetailModel from "./component/goodDetailModel.vue";
import SKUModel from "./component/SKUModel.vue";
import InventoryProcess from "./component/InventoryProcess.vue";
import authentication from "./component/authentication.vue";
export default {
  name: "addTableModel",
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
          data: {}, //编辑数据回填
        };
      },
    },
  },
  data() {
    return {
      selectPane: "1",
      customized: false,
      inventory: false,
      inventoryInfo: [],
      goodsForm:'',
    };
  },
  methods: {
    handleCancel(e) {
      if (e.target.localName === "svg") {
        this.config.visible = false;
      }
    },
    async handleOk() {
     
      let { isok, data } = await this.$refs.formOne.validateForm();
      if (isok === false) {
        this.selectPane = "1";
        return false;
      }
      if (this.customized) {
        if (!this.$refs.formTwo) {
          this.selectPane = "2";
          return false;
        }
        let res2 = await this.$refs.formTwo?.validateForm();
        if (!res2) {
          this.$message.error("SKU属性未填写完整");
          this.selectPane = "2";
          return false;
        }
      }
      if (this.inventory) {
        let res3 = await this.$refs.formFore?.validateForm();
        if (!res3) {
          this.$message.error("库存处理属性未填写完整");
          this.selectPane = "4";
          return false;
        }
      }

      let threeData = this.$refs.formThree?.form;
      if (!this.$refs.formThree && this.config.type !== "edit") {
        return (this.selectPane = "3");
      } else if (!this.$refs.formThree && this.config.type === "edit") {
        threeData = {
          productQualification: this.config.data.productQualification,
          qualificationImages: this.config.data.qualificationImages,
        };
      }
      if (this.$refs.formThree?.form) {
        let res3 = await this.$refs.formThree.validateForm();
        if (!res3) {
          this.$message.error("商品品质未填写完整");
          return (this.selectPane = "3");
        }
      }
      this.$refs.formFore?.data.forEach((item) => {
        item.inventoryNum = item.inventoryNumer + "," + item.inventoryConfig;
      });
      this.handleImages(this.$refs.formFore?.data)
      let target = {
        ...data,
        skuvos: this.$refs.formTwo?.data,
        inventoryList: this.$refs.formFore?.data,
        ...threeData,
        productQualification: threeData.productQualification.toString(),
      };
      return { isok: true, target };
    },
    // 处理库存处理图片
    handleImages(data) {
      data.map(v=>{
        if(typeof v.inventoryImages==='object'){
          v.inventoryImages=v.inventoryImages[0]
        }
      })
    },
    // 暂存
    async handleTempSave() {
      let { isok, data } = await this.$refs.formOne.validateForm("noValidate");
      if (isok === false) {
        this.selectPane = "1";
        return false;
      }
      if (!this.$refs.formTwo) {
        this.selectPane = "2";
        return false;
      }
      if (!this.$refs.formFore) {
        this.selectPane = "4";
        return false;
      }
      let threeData = this.$refs.formThree?.form;
      if (!this.$refs.formThree && this.config.type !== "edit") {
        return (this.selectPane = "3");
      } else if (!this.$refs.formThree && this.config.type === "edit") {
        threeData = {
          productQualification: this.config.data.productQualification,
          qualificationImages: this.config.data.qualificationImages,
        };
      }
      this.$refs.formFore?.data.forEach((item) => {
        if(!item.inventoryNumer || !item.inventoryConfig){
          item.inventoryNum=0
        }else{
          item.inventoryNum = item.inventoryNumer + "," + item.inventoryConfig;
        }
      });
      this.handleImages(this.$refs.formFore?.data)
      let target = {
        ...data,
        skuvos: this.$refs.formTwo.data,
        inventoryList: this.$refs.formFore?.data,
        ...threeData,
        productQualification: threeData.productQualification.toString(),
      };
      return { isok: true, target };
    },
    // 监听业务定制选择内容，拿到最新数据
    handelConfig(value) {
      this.goodsForm=value.inventoryConfig
      if (value.customized) {
        this.customized = true;
      } else {
        this.customized = false;
      }
      if (value.inventory) {
        this.inventory = true;
      } else {
        this.inventory = false;
      }
    },
    getSKU(value) {
      this.inventoryInfo = value;
    },
  },
  components: {
    goodDetailModel,
    SKUModel,
    authentication,
    InventoryProcess,
  },
};
</script>

<style scoped lang="scss">
.detail-box {
  display: flex;
  justify-content: space-around;
  .left,
  .right {
    width: 48%;
    .right-item,
    .left-item {
      height: 30px;
      line-height: 30px;
    }
  }
  .right {
    .right-item:nth-child(4) {
      display: flex;
      /deep/.ant-radio-group {
        display: flex;
        height: 30px;
        align-items: center;
        padding-left: 20px;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="search-box top-container" style="height: 181px">
      <div class="lable-box search-item">
        <label class="label">工厂名称:</label>
        <a-input v-model="searchText.factoryName" placeholder="请输入" />
      </div>
      <div class="lable-box search-item">
        <label class="label">工厂地址:</label>
        <a-input v-model="searchText.factoryAddress" placeholder="请输入" />
      </div>
      <div class="lable-box search-item">
        <label class="label">工厂联系人:</label>
        <a-input v-model="searchText.factoryLinkman" placeholder="请输入" />
      </div>
      <div class="lable-box search-item">
        <label class="label">联系电话:</label>
        <a-input v-model="searchText.factoryPhonenumber" placeholder="请输入" />
      </div>
      <div class="lable-box search-item">
        <label class="label">验厂状态:</label>
        <a-select v-model="searchText.ifinspection" placeholder="请选择">
          <a-select-option
            v-for="(item, index) in ifinspectOption"
            :key="index"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="lable-box search-item">
        <label class="label">标签：</label>
        <a-select v-model="searchText.factoryTag" placeholder="请选择">
          <a-select-option
            v-for="(tagItem, tagIndex) in factoryTag"
            :key="tagIndex"
            :value="tagItem"
          >
            {{ tagItem }}
          </a-select-option>
        </a-select>
      </div>
      <div class="search-item right-btn">
        <a-button @click="resetData" class="button-item"> 重置 </a-button>
        <a-button @click="search" class="button-item"> 查询 </a-button>
      </div>
    </div>
    <div class="bottom-container">
      <a-button
        type="primary"
        @click="addFactory('add')"
        style="margin-bottom: 20px"
      >
        新增
      </a-button>
      <a-table
        :columns="columns"
        :pagination="pagination"
        :data-source="data"
        :scroll="{ y: 375 }"
        :loading="loading"
        :key="data.key"
      >
        <template slot="ifinspection" slot-scope="text, record">
          <div>
            <button
              class="if-inspection"
              :style="{
                'background-color':
                  record.ifinspection === true ? '#52c41a' : '#FF4D4F',
              }"
            ></button
            ><span> {{ ifinspectEnm[record.ifinspection] }}</span>
          </div>
        </template>
        <template slot="factoryTag" slot-scope="text, record">
          <div>
            <button
              class="factory-tag"
              v-for="(item, index) in record.factoryTag"
              :key="index"
            >
              {{ item }}
            </button>
          </div>
        </template>
        <template slot="option" slot-scope="text, record">
          <span
            @click="addFactory('edit', record)"
            style="color: rgb(255, 70, 0); cursor: pointer"
          >
            编辑
          </span>
          <span
            @click="handleDeteil(record)"
            style="color: rgb(255, 70, 0); cursor: pointer"
          >
            详情
          </span>
          <span
            @click="openFactoryInspect(record)"
            style="color: rgb(255, 70, 0); cursor: pointer"
          >
            验厂
          </span>
        </template>
      </a-table>
    </div>
    <detailModel :config="detailConfig" v-if="detailConfig.visible" />
    <addModel :config="addConfig" v-if="addConfig.visible" />
    <factoryInspection
      :config="factoryConfig"
      v-if="factoryConfig.visible"
      @configData="factoryData"
    />
  </div>
</template>

<script>
import detailModel from "./component/detailModel.vue";
import addModel from "./component/addModel.vue";
import factoryInspection from "./component/factoryInspection.vue";
import { factoryApi } from "@/api/FactoryQuery.js";
import pagination from "@/mixins/Pagination";
import { setKey } from "@/utils/utils.js";
const columns = [
  {
    dataIndex: "factoryName",
    key: "factoryName",
    title: "工厂名称",
    ellipsis: true,
    scopedSlots: { customRender: "factoryName" },
  },
  {
    title: "工厂地址",
    dataIndex: "factoryAddress",
    ellipsis: true,
    key: "factoryAddress",
  },
  {
    title: "工厂联系人",
    dataIndex: "factoryLinkman",
    ellipsis: true,
    key: "factoryLinkman",
  },
  {
    title: "验厂状态",
    dataIndex: "ifinspection",
    ellipsis: true,
    key: "ifinspection",
    width: 150,
    scopedSlots: { customRender: "ifinspection" },
  },
  {
    title: "标签",
    dataIndex: "factoryTag",
    ellipsis: true,
    key: "factoryTag",
    width: 200,
    scopedSlots: { customRender: "factoryTag" },
  },
  {
    title: "联系电话",
    key: "factoryPhonenumber",
    dataIndex: "factoryPhonenumber",
    ellipsis: true,
    scopedSlots: { customRender: "factory_phonenumber" },
  },
  {
    title: "操作",
    key: "action",
    width: "150px",
    scopedSlots: { customRender: "option" },
  },
];
const data = [];
export default {
  data() {
    return {
      data,
      columns,
      ifinspectOption: [
        {
          label: "已验厂",
          value: "true",
        },
        {
          label: "未验厂",
          value: "false",
        },
      ],
      ifinspectEnm: {
        true: "已验厂",
        false: "未验厂",
      },
      factoryTag: [
        "高新技术企业",
        "数字化工厂高新技术企业",
        "数字化工厂",
        "独立品牌",
        "单项冠军",
        "科技领军企业",
        "省市级技术研发中心",
        "国家火炬计划",
        "行业十强",
        "制造业企业500强",
        "专精特新“小巨人”",
        "上市企业",
        "外贸出口",
      ], //标签，值待定
      detailConfig: {
        visible: false,
      },
      addConfig: {
        visible: false,
        type: null,
      },
      factoryConfig: {
        visible: false,
        type: null,
        data: {
          cloudFactory: [
            {
              file: "", //文件地址
              fileType: 0, //文件类型
              name: null, //云上工厂显示名称
              isServse: false,
              isEdit: false,
            },
          ], //云上工厂
          facadeVedio: null, //门面视频
          factoryId: null, //工厂id
          factoryIntroduce: null, //企业介绍
          factoryMainDiagram: "", //工厂主图
          factoryMarketingName: null, //工厂营销负责人
          factoryMarketingPhonenumber: null, //工厂营销负责人联系电话
          factoryName: null, //工厂名称
          factoryQualification: [
            {
              file: "", //资质图片地址
              name: null, //资质名称
            },
          ], //工厂资质
          factoryTag: [], //工厂标签
          ifwechatnumber: true, //是否微信同号
          manageScope: null, //经营范围
          productManual: null, //电子产品册
        },
      },
      searchText: {
        factoryName: null, //工厂名称
        factoryAddress: null, //工厂地址
        factoryLinkman: null, //工厂联系人
        factoryPhonenumber: null, //联系电话
        ifinspection: undefined, //验厂状态
        factoryTag: undefined, //标签
      },
      loading: false,
    };
  },
  mixins: [pagination],
  components: {
    detailModel,
    addModel,
    factoryInspection,
  },
  methods: {
    async handleDeteil(item) {
      let data = {
        factoryId: item.factoryId,
      };
      let res = await factoryApi.getfactdetail(data);
      if (res.success) {
        this.detailConfig.visible = true;
        this.detailConfig.data = res.data;
      }
    },

    async openFactoryInspect(record) {
      // 未验厂状态为该工厂新增验厂信息
      if (record.ifinspection === false) {
        this.factoryConfig.type = "新增";
        if (!this.factoryConfig.data.factoryId) {
          this.factoryConfig.data.factoryId = record.factoryId;
        }
        if (this.factoryConfig.data.factoryId !== record.factoryId) {
          this.factoryConfig.data = {
            cloudFactory: [
              {
                file: "", //文件地址
                fileType: 0, //文件类型
                name: null, //云上工厂显示名称
                isServse: false,
                isEdit: false,
              },
            ],
            factoryQualification: [
              {
                file: "", //资质图片地址
                name: null, //资质名称
              },
            ],
          };
        } else {
          if (record.factoryTag?.length) {
            this.factoryConfig.data.factoryTag = record.factoryTag;
          }
        }
        if (!this.factoryConfig.data.factoryMarketingName) {
          this.factoryConfig.data.factoryMarketingName = record.factoryLinkman;
        }
        if (!this.factoryConfig.data.factoryMarketingPhonenumber) {
          this.factoryConfig.data.factoryMarketingPhonenumber =
            record.factoryPhonenumber;
        }
        this.factoryConfig.data.factoryName = record.factoryName;
      } else {
        // 已验厂状态为该工厂更新验厂信息
        this.factoryConfig.type = "更新";
        const id = {
          factoryId: record.factoryId,
        };
        try {
          const res = await factoryApi.inspectionDetail(id);
          this.factoryConfig.data = res.data;
          if (!this.factoryConfig.data.factoryTag) {
            this.factoryConfig.factoryTag = null;
          }
          // 云上工厂（添加、编辑、删除、取消保存按钮）
          this.factoryConfig.data?.cloudFactory.map((item) => {
            item.isEdit = false;
            item.isServse = true;
          });
          // // 云上资质（添加、编辑、删除、取消保存按钮）
          this.factoryConfig.data?.factoryQualification.map((item) => {
            item.isEdit = false;
            item.isServse = true;
          });
          console.log(this.factoryConfig.data);
        } catch (error) {
          throw new Error(error);
        }
      }
      this.factoryConfig.visible = true;
    },
    factoryData(val) {
      this.factoryConfig.data = val;
    },
    async getBaseData() {
      this.loading = true;
      let baseParmase = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        factoryName: this.searchText.factoryName,
        factoryAddress: this.searchText.factoryAddress,
        factoryLinkman: this.searchText.factoryLinkman,
        factoryPhonenumber: this.searchText.factoryPhonenumber,
        ifinspection: this.searchText.ifinspection,
        factoryTag: this.searchText.factoryTag,
      };
      try {
        let res = await factoryApi.getBaseData(baseParmase);
        if (this.pagination.current > 1 && res.data.records.length == 0) {
          this.pagination.current -= 1;
          this.getBaseData();
        }
        this.pagination.total = res.data.total;
        this.data = setKey(res.data.records);
        this.data.map((item) => {
          item.factoryTag = item.factoryTag
            ? item.factoryTag.split(",")
            : item.factoryTag;
          return item;
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getBaseData();
    },
    onPage(e) {
      this.pagination.current = e;
      this.getBaseData();
    },
    resetData() {
      this.searchText = {
        factoryName: null, //工厂名称
        factoryAddress: null, //工厂地址
        factoryLinkman: null, //工厂联系人
        factoryPhonenumber: null, //联系电话
        ifinspection: undefined, //验厂状态
        factoryTag: undefined, //标签
      };
      this.pagination.current = 1;
      this.getBaseData();
    },
    search() {
      this.pagination.current = 1;
      this.getBaseData();
    },
    async addFactory(type, record) {
      if (type === "edit") {
        let data = {
          factoryId: record.factoryId,
        };
        let res = await factoryApi.getfactdetail(data);
        this.addConfig.data = res.data;
      }
      this.addConfig.type = type;
      this.addConfig.visible = true;
    },
  },
  mounted() {
    this.getBaseData();
  },
};
</script>

<style scoped lang="scss">
.search-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .search-item {
    width: 26%;
    text-align: right;
    .button-item {
      margin-left: 10px;
      &:nth-child(2) {
        background-color: #ff4600;
        color: #fff;
      }
    }
  }
  .right-btn {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
}
.ant-table-wrapper {
  // margin-top: 20px;
  /deep/.ant-table-thead > tr > th {
    background-color: #ffe9db;
  }
}
.label {
  width: 110px;
  line-height: 34px;
  text-align: right;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.if-inspection {
  width: 6px;
  height: 6px;
  border: unset;
  border: 4px;
}
.factory-tag {
  margin-right: 10px;
  height: 20px;
  color: #fe8432;
  background: rgba(254, 132, 50, 0.1);
  border: unset;
}
</style>

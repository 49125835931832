<template>
  <div :style="{ position: 'relative' }">
    <a-select
      show-search
      showArrow
      :value="value"
      :style="{ width: '28.5rem' }"
      option-filter-prop="children"
      :filter-option="filterOpt"
      :mode="config.mode"
      @change="handleChange"
      @popupScroll="Scroll"
      @search="handleSearch"
      @blur="handleBlur"
      @keydown.native.enter="searchData"
      :class="{ mutiplt: mutiple }"
      :optionLabelProp="config.optionLabelProp"
      :disabled="disabled"
      v-bind="$attrs"
      placeholder="请选择"
    >
      <a-select-option
        v-for="(d, index) in data"
        :key="index"
        :value="d[config.valid]"
      >
        {{ d[config.vallable] }}
      </a-select-option>
    </a-select>
    <slot :query="search"></slot>
  </div>
</template>
<script>
import axios from "@/config/http";
import debounce from "lodash/debounce";
import { uniqueFunc, filterOpt } from "@/utils/utils.js";
export default {
  name: "SelectPullpRefresh",
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          url: "", //根据自己定义的url，将路径传入参数
          placeholder: undefined,
          valid: "", //数据val的id值
          vallable: "", //数据的label值
          path: ["data", "records"], //数据在data的位置
          mode: "tags", //是否多选
          queryText: "labelName", //搜索字段
          metadataId: null, //对象实例特殊情况
          optionLabelProp: "children", //选择框选中值 value为
        };
      },
    },
    value: {
      //选中值
      default: undefined,
    },
    targetName: {
      //数据回填的目标,
      type: Array,
      default() {
        return;
      },
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      data: [],
      page: 1,
      total: 0,
      val: "",
      loadMoreLoading: true,
      timer: null,
      search: "",
    };
  },
  methods: {
    filterOpt,
    handleChange(value) {
      // 最多选中5项
      if (value.length > 5 && this.config.mode === "multiple") return;
      this.$emit("input", value);
      this.$emit("filter", value, this.data);
    },
    handleSearch(value) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.search = value;
        this.reSetListData();
      }, 500);
    },
    handleBlur() {
      this.$emit("inputBlur");
      this.$emit("blur");
      this.search = "";
      this.reSetListData();
    },
    Scroll: debounce(function () {
      this.loadMoreData();
    }, 500),

    // 加载更多数据到select框
    loadMoreData() {
      if (this.data.length < this.total) {
        this.page = +this.page + 1;
        this.getMenu().then((res) => {
          let target = this.fromet(res);
          target.forEach((v) => {
            this.data.push(v);
          });
        });
      }
    },
    searchData(e) {
      e.stopPropagation();
      this.$emit("searchData");
    },
    //获取借口请求数据
    async getMenu() {
      if (this.loadMoreLoading) {
        this.loadMoreLoading = false;
        let params;
        if (this.config.queryText) {
          params = {
            [this.config.queryText]: this.search,
            size: 10,
            page: this.page,
          };
        } else {
          params = {
            size: 10,
            page: this.page,
          };
        }
        if (this.config.metadataId) params.metadataId = this.config.metadataId;
        return new Promise((resolve) => {
          axios.get(`${this.config.url}`, { params }).then((res) => {
            if (this.fromet(res).length === 10) {
              this.loadMoreLoading = true;
            }
            resolve(res);
          });
        });
      }
    },
    //格式转换
    fromet(data) {
      let datas = data;
      this.config.path.map((i) => {
        datas = datas[i];
      });
      return datas;
    },
    // 执行一遍mounted
    reSetListData() {
      if (this.config.url === "") return;
      this.page = 1;
      this.loadMoreLoading = true;
      this.getMenu().then((res) => {
        if (res.data.total) {
          this.total = res.data.total;
        }
        this.data = this.fromet(res);
        if (this.targetName) {
          let arr = [...this.data, ...this.targetName];
          this.data = uniqueFunc(arr, this.config.valid);
        }
      });
    },
  },
  mounted() {
    if (this.config.url === "") {
      return;
    } else {
      this.getMenu().then((res) => {
        this.data = this.fromet(res);
        if (this.targetName) {
          let arr = [...this.data, ...this.targetName];
          this.data = uniqueFunc(arr, this.config.valid);
        }
        if (res.data.total) {
          this.total = res.data.total;
        }
      });
    }
  },
  computed: {
    mutiple() {
      let result = this.config.val || [];
      return result.length >= 6;
    },
  },
  watch: {
    value(newVal) {
      this.$emit("change", newVal);
      this.$emit("filter", newVal, this.data);
    },
  },
};
</script>
<style scoped lang="scss">
.mutiplt {
  ::v-deep .ant-select-selection--multiple {
    max-height: 60px;
    overflow-y: auto;
  }
}
</style>

export const rules = {
  username: [{ required: true, message: "请输入名字", trigger: "blur" }],
  usernumber: [
    { required: true, message: "请输入员工工号", trigger: "blur" },
    {
      validator: function (rule, value, callback) {
        const reg = /^[A-Za-z0-9-]+$/;
        if (value && !reg.test(value)) {
          callback(new Error("只支持数字+字母和-字符"));
        }
        callback();
      },
    },
  ],
  remark: [{ required: true, message: "备注不能为空", trigger: "blur" }],
  select: [{ required: true, message: "员工不能为空", trigger: "blur" }],
  code: [{ required: true, message: "账号不能为空", trigger: "change" }],
  employeeId: [{ required: true, message: "员工不能为空", trigger: "blur" }],
  name: [
    { required: true, message: "角色名不能为空", trigger: "blur" },
    { min: 2, max: 10, message: "角色名在2个至10个之间", trigger: "blur" },
  ],
  accountId: [{ required: true, message: "账号不能为空", trigger: "blur" }],
  state: [{ required: true, message: "状态不能为空", trigger: "blur" }],
  roleIds: [{ required: true, message: "角色不能为空", trigger: "change" }],
  userbrach: [{ required: true, message: "请选择部门", trigger: "change" }],
  // userjob:[
  //   { required: true, message: "请选择所属部门", trigger: "blur" },
  // ],
  usermobile: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    {
      pattern:
        /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
      message: "手机号码格式不正确,请检查",
    },
    // {type: "number",message: "请输入正确的手机号",trigger: "blur"}
  ],
  useremail: [
    {
      /* eslint-disable */
      pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
      message: "请输入正确的邮箱",
      trigger: "blur",
    },
  ],
  // 权限
  labelName: [
    { required: true, message: "权限组名称不能为空", trigger: "blur" },
    {
      pattern: /^(?!_|[0-9])[a-zA-Z0-9_\u4e00-\u9fa5]{4,50}$/,
      message:
        "权限组格式不正确，仅支持中文、英文、数字、下划线（_），且只能以英文或中文开头，4~50个字符。",
    },
  ],

  //上架申请开始
  goodsNameCn: [
    { required: true, message: "请输入商品中文名称", trigger: "blur" },
    { max: 32, message: "中文名称最多32位", trigger: "blur" },
  ],
  goodsNameEn: [
    { required: true, message: "请输入商品英文名称", trigger: "blur" },
    { max: 128, message: "英文名称最多128位", trigger: "blur" },
  ],
  tenantId: [{ required: true, message: "请选择工厂名称", trigger: "blur" }],
  category_id: [{ required: true, message: "请输入选品分类", trigger: "blur" }],
  brandName: [
    { required: true, message: "请输入品牌名称", trigger: "blur" },
    { max: 15, message: "品牌最多15位字符", trigger: "blur" },
  ],
  specialTag: [{ required: true, message: "请输入特殊标签", trigger: "blur" }],
  customTags: [
    { required: true, message: "请输入自定义标签", trigger: "blur" },
  ],
  warranty: [{ required: true, message: "请输入质保期", trigger: "blur" }],
  productionCycle: [
    { required: true, message: "请输入生产周期", trigger: "blur" },
  ],

  packingList: [
    { required: true, message: "请输入包装清单", trigger: "blur" },
    { max: 128, message: "包装清单最多128位", trigger: "blur" },
  ],
  commoditySellingPoint: [
    { required: true, message: "请输入商品卖点", trigger: "blur" },
    { max: 256, message: "包装清单最多256位", trigger: "blur" },
  ],
  customcheck:[{ required: true, message: "请选择支持业务", trigger: "blur" }],
  minOrder: [{ required: true, message: "请输入定制起订量", trigger: "blur" }],
  address: [
    { required: true, message: "请输入现货所在地", trigger: "change" },
    { required: true,max: 128, message: "现货所在地最多128位字符", trigger: "change" },
  ],
  inventoryTags: [
    { required: true, message: "请输入库存处理标签", trigger: "blur" },
    { required: true, message: "请输入库存处理标签", trigger: "chenge" },
    // { max: 128, message: "现货所在地最多128位字符", trigger: "blur" },
  ],
  minInventory: [
    { required: true, message: "请输入库存起批量", trigger: "blur" },
  ],
  spotSpecification: [
    { required: true, message: "请选择现货规格", trigger: "change" },
  ],
  availableArea: [
    { required: true, message: "请选择可售区域", trigger: "blur" },
  ],
  grossWeight: [{ required: true, message: "请输入商品毛重", trigger: "blur" }],
  netWeight: [{ required: true, message: "请输入商品净重", trigger: "blur" }],
  packingSize: [{ required: true, message: "请输入包装尺寸", trigger: "blur" }],
  outerPackingSize: [
    { required: true, message: "请输入外包装尺寸", trigger: "blur" },
  ],
  goodsSize: [{ required: true, message: "请输入商品尺寸", trigger: "blur" }],
  casesGauge: [{ required: true, message: "请输入箱规", trigger: "blur" }],
  spuImags: [{ required: true, message: "请上传SPU图片", trigger: "blur" }],
  detailImages: [
    { required: true, message: "请上传商品详情图片", trigger: "blur" },
  ],
  packagingImags: [
    { required: true, message: "请上传包装图片", trigger: "blur" },
  ],
  goodsCategoryId: [{ required: true, message: "请选择分类", trigger: "blur" }],
  qualificationImages: [
    { required: true, message: "请输入商品认证报告", trigger: "blur" },
  ],
  // 上架申请结束

  // 订单审核
  contractPrice: [
    { required: true, message: "请输入合同金额", trigger: "blur" },
  ],
  contractFiles: [
    { required: true, message: "请上传双边合同", trigger: "blur" },
  ],
  paymentVouchers: [
    { required: true, message: "请上传预付款凭证", trigger: "blur" },
  ],
  onPrice: [{ required: true, message: "请输入基础价", trigger: "blur" }],
  timeDelivery: [
    { required: true, message: "请输入交货日期", trigger: "blur" },
  ],
  overMonadVouchers: [
    { required: true, message: "请上传结单凭证", trigger: "blur" },
  ],
  productQualification: [
    { required: true, message: "请输入商品认证", trigger: "blur" },
  ],

  // 登录开始
  ueserCount: [{ required: true, message: "请输入账号", trigger: "blur" }],
  password: [{ required: true, message: "请输入密码", trigger: "blur" }],

  // 工厂查询开始
  factoryName: [
    { required: true, message: "请输入工厂名称", trigger: "blur" },
    { max: 64, message: "工厂名称最长为64个字符", trigger: "blur" },
  ],
  factoryCreditCode: [
    { required: true, message: "请输入统一社会信用代码", trigger: "blur" },
    { max: 20, message: "统一社会信用代码最长为20个字符", trigger: "blur" },
  ],
  factoryAddress: [
    { required: true, message: "请输入工厂地址", trigger: "blur" },
    { max: 64, message: "工厂地址最长为64个字符", trigger: "blur" },
  ],
  manageScope: [
    { required: true, message: "请输入经营范围", trigger: "blur" },
    { max: 64, message: "经营范围最长为64个字符", trigger: "blur" },
  ],
  factoryLinkman: [
    { required: true, message: "请输入联系人", trigger: "blur" },
    { max: 32, message: "联系人最长为32个字符", trigger: "blur" },
  ],
  factoryPhonenumber: [
    { required: true, message: "请输入联系电话", trigger: "blur" },
    { max: 64, message: "联系电话最长为64个字符", trigger: "blur" },
  ],
  factoryLicense: [
    { required: true, message: "请添加营业执照", trigger: "blur" },
  ],
  facadePic: [{ required: true, message: "请添加门面照片", trigger: "blur" }],
  ifsend: [
    { required: true, message: "请输入是否支持一件代发", trigger: "blur" },
  ],
  // 工厂查询结束
  orderComplete: [
    { required: true, message: "请输入订单完成数", trigger: "blur" },
  ],
  orderBadness: [
    { required: true, message: "请输入不良品完成数量", trigger: "blur" },
  ],
  reason: [{ required: true, message: "请输入终止原因", trigger: "blur" }],
  // 验厂

};
export const factoryRules = {
  // 工厂营销负责人
  factoryMarketingName: [{ required: true, message: "请输入工厂营销负责人", trigger: "blur" }],
  // 联系方式
  factoryMarketingPhonenumber: [{ required: true, message: "请输入联系方式", trigger: "blur" }],

  // 主营产品
  manageScope: [{ required: true, message: "请输入主营产品", trigger: "blur" }],

  // 企业介绍
  factoryIntroduce: [{ required: true, message: "请输入企业介绍", trigger: "blur" }],

  // 主页视频
  facadeVedio: [{ required: true, message: "请添加主页视频", trigger: "blur" }],

  // 工厂主图
  factoryMainDiagram: [{ required: true, message: "请添加工厂主图", trigger: "blur" }],
  // 云上工厂
  // 工厂资质
}
// 商户管理-商户查询-服务升级校验
export const upgradeRules={
  // 有效期
  maturityTime: [{ required: true, message: "请输入有效期", trigger: "change" }],
  buyNumber: [{ required: true, message: "请输入查看工厂家数", trigger: "blur" }],
  // 购买凭证
  payVouchers: [{ required: true, message: "请添加购买凭证", trigger: "blur" }],
}

// 
export const payServiceRules={
  // 工作人员
  contact: [
    { required: true, message: "请输入工作人员", trigger: "blur" },
    { max: 30, message: "工作人员最长为30个字符", trigger: "blur" },
  ],
  // 联系方式
  phone: [
    { required: true, message: "请输入联系方式", trigger: "blur" },
    { max: 30, message: "联系方式最长为30个字符", trigger: "blur" },
  ],
  // 服务内容
  serviceInfoList: [
    { required: true, message: "请输入服务内容", trigger: "blur" },
    // { max: 200, message: "服务内容最长为200个字符", trigger: "blur" },
  ],
}
<template>
  <div class="box">
    <a-input-number
      placeholder="请输入"
      @blur="handleBlur"
      :value="value"
      @input="handleInput"
      v-bind="$attrs"
      :min="1"
      style="width:140px"
    />
    <a-select
      style="width: 80px"
      v-bind="$attrs"
      @change="handleChage"
      :value="CompanyNum"
      :disabled="isDisabled"
    >
      <a-select-option v-for="item in config" :key="item.dictValue" :value="item.dictValue">
        {{ item.dictValue }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      default: "", //单位数值
    },
    CompanyNum: {
      default: "",
    }, // 单位的大小
    isDisabled:{
      default:false
    }//选择框是否可以点击
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
    handleChage(e) {
      this.$emit("update:CompanyNum", e);
    },
    handleBlur(e) {
      if (isNaN(e.target.value)) {
        this.$emit("input",undefined);
        return this.$message.error("值输入错误");
      }
      // if (Math.floor(e.target.value) <= 0) {
      //   this.$emit("input",1);
      //   return this.$message.error("值输入最小值为1");
      // }
      let target = Math.floor(e.target.value);
      this.$emit("input",target);
      this.$emit("blur");
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
}
</style>
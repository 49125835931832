import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import less from "less";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import "./assets/css/base.scss";
// 图片预览
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

import "./Mock";

// 雷达图
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  RadarChart,
  CanvasRenderer,
]);

import { changeTab } from "./components/SideMenu/menu.js";
import LocalCache from "@/utils/cache.js";
import NProgress from "nprogress"; //顶部进度条

import { MenuList, menuInfoFilter } from "@/enum/index.js";

import "./icons";
Vue.use(less);
Vue.use(Antd);

Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: "data-source",
  },
});

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  // 先判断有没有登录，如果有就可以访问其他页面，如果没有返回登录页
  let target = LocalCache.getCache("token");
  if (target) {
    // 登录状态下去登录页不让去
    if (to.path === "/login") {
      NProgress.start();
      changeTab(to);
      next("/shelfApplication");
    } else {
      NProgress.start();
      changeTab(to);
      next();
    }
  } else {
    // 非登录状态下去登录不定向
    if (to.path !== "/login") {
      // 不是去登陆
      next("/login");
    } else {
      // 去登陆
      next();
    }
  }

  // 路由跳转时，判断路由存不存在，不存在跳转404
  let res = store.state.Login.roleIds;
  let result = menuInfoFilter(MenuList, res);
  if (result) {
    let routerArr = result.map((item) => {
      return item.path;
    });
    if (!routerArr.includes(to.path)) {
      alert("请输入正确的路由");
    }
  }
});
router.afterEach(() => {
  NProgress.done();
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  created(){
    document.title = '0距离选品后台管理'
  }
};
</script>

<style lang="scss">
  @import "@/assets/css/base.scss";
  @import "@/assets/css/ant.scss";
  @import "@/_variables.scss";
</style>

import Vue from "vue";
import VueRouter from "vue-router";
//import index from "../views/index.vue";
import login from "../views/login";
import FactoryQuery from "../views/FactoryQuery/index.vue";
import ShelfApplication from "../views/ShelfApplication/index.vue";
const originalPush = VueRouter.prototype.push;

// 解决同一路由跳转报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      title: "首页",
    },
    redirect: "/factoryQuery",
    component: ()=>import('@/views/index.vue'),
    children: [
      {
        path: "/factoryQuery",
        name: "FactoryQuery",
        meta: {
          title: "工厂查询",
        },
        component: FactoryQuery,
      },
      {
        path: "/shelfApplication",
        name: "ShelfApplication",
        meta: {
          title: "上架申请",
        },
        component: ShelfApplication,
      },
      {
        path: "/selectiongoods",
        name: "selectiongoods",
        meta: {
          title: "选品上架",
        },
        component: () => import("@/views/SelectionGoods/index.vue"),
      },
      {
        path: "/merchantQuery",
        name: "merchantQuery",
        meta: {
          title: "商户查询",
        },
        component: () => import("@/views/MerchantQuery/index.vue"),
      },
      {
        path: "/orderreview",
        name: "orderreview",
        meta: {
          title: "订单审核",
        },
        component: () => import("@/views/orderreview/index.vue"),
      },
      {
        path: "/advertisementManage",
        name: "advertisementManage",
        meta: {
          title: "广告管理",
        },
        component: () => import("@/views/AdvertisementManage/index.vue"),
      },
      {
        path: "/explosiveManage",
        name: "explosiveManage",
        meta: {
          title: "爆品管理",
        },
        component: () => import("@/views/ExplosiveManage/index.vue"),
      },
      {
        path: "/newManage",
        name: "newManage",
        meta: {
          title: "新品管理",
        },
        component: () => import("@/views/NewManage/index.vue"),
      },
      {
        path: "/merchantAnalysis",
        name: "merchantAnalysis",
        meta: {
          title: "商户分析",
        },
        component: () => import("@/views/MerchantAnalysis/index.vue"),
      },
      {
        path: "/selectionAnalysis",
        name: "selectionAnalysis",
        meta: {
          title: "选品分析",
        },
        component: () => import("@/views/SelectionAnalysis/index.vue"),
      },
      {
        path: "/searchStatistics",
        name: "searchStatistics",
        meta: {
          title: "搜索统计",
        },
        component: () => import("@/views/SearchStatistics/index.vue"),
      },
      {
        path: "/classification",
        name: "Classification",
        meta: {
          title: "选品分类",
        },
        component: () => import("@/views/Classification/index.vue"),
      },
      {
        path: "/dataDictionary",
        name: "dataDictionary",
        meta: {
          title: "数据字典",
        },
        component: () => import("@/views/DataDictionary/index.vue"),
      },
      {
        path: "/evaluationSystem",
        name: "evaluationSystem",
        meta: {
          title: "评价体系",
        },
        component: () => import("@/views/EvaluationSystem/index.vue"),
      },
      {
        path: "/evaluationFactory",
        name: "evaluationFactory",
        meta: {
          title: "工厂评价",
        },
        component: () => import("@/views/EvaluationFactory/index.vue"),
      },
      {
        path: "/extensionApply",
        name: "extensionApply",
        meta: {
          title: "延期申请",
        },
        component: () => import("@/views/ExtensionApply/index.vue"),
      },
      {
        path: "/extensionExamine",
        name: "extensionExamine",
        meta: {
          title: "延期审核",
        },
        component: () => import("@/views/ExtensionExamine/index.vue"),
      },
      {
        path: "/leavingManage",
        name: "leavingManage",
        meta: {
          title: "留言管理",
        },
        component: () => import("@/views/LeavingManage/index.vue"),
      },
      {
        path: "/userManage",
        name: "userManage",
        meta: {
          title: "用户管理",
        },
        component: () => import("@/views/UserManage/index.vue"),
      },
      {
        path: "/departmentManage",
        name: "departmentManage",
        meta: {
          title: "部门管理",
        },
        component: () => import("@/views/DepartmentManage/index.vue"),
      },
      {
        path: "/popularFactory",
        name: "popularFactory",
        meta: {
          title: "热门工厂",
        },
        component: () => import("@/views/PopularFactory/index.vue"),
      },
      {
        path: "/servicePayment",
        name: "servicePayment",
        meta: {
          title: "服务付费记录",
        },
        component: () => import("@/views/ServicePaymentRecord/index.vue"),
      },
      {
        path: "/payService",
        name: "payService",
        meta: {
          title: "付费服务",
        },
        component: () => import("@/views/PayService/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;

/**
 * axios实例封装，在这里加上项目对接口请求的统一处理
 */
import axios from "axios";
import config from "./config";
import store from "@/store";
// import router from '@/router'
import message from "ant-design-vue/es/message";
const { apiHost } = config;
const option = {
  baseURL: apiHost,
  timeout: 20000, // 请求超时时间
};
// 创建 axios 实例Authorization:
const service = axios.create(option);
service.interceptors.request.use(
  (config) => {
    config.headers.Authorization =
      store.state.Login.token === "" ? "" : "Bearer " + store.state.Login.token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // console.log("response", response);
    // console.log(typeof res);
    // console.log(Object.keys(response.headers).includes("content-disposition"));
    if (Object.keys(response.headers).includes("content-disposition")) {
      return response;
    }
    // 判断处理结果是文件类型时，不对结果集处理，直接返回
    if (res instanceof Blob) {
      return response;
    }
    // 这里需要根据不同的项目后端接口封装情况做适当调整
    // 判断401
    // console.log('response', response)
    // if(response.status === 401){
    //   console.log(window);
    //   window.history.pushState({},'','http://192.168.233.181/login')
    // }
    if (res?.code == "401" && !res.success) {
      store.dispatch("Login/loginout");
      location.href = `${location.origin}/login`;
    }

    if (res.isError) {
      message.error((res && res.message) || "未知异常！");
      return Promise.reject(new Error((res && res.message) || "未知异常！"));
    } else {
      return res;
    }
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch("Login/loginout");
      console.log("error捕捉到了", error);
      location.href = `${location.origin}/login`;
    }
    if (axios.isCancel(error)) {
      return null;
    }
    return Promise.reject(error);
  }
);

export default service;

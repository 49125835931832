import { LoginAPi } from "@/api/login.js";
import LocalCache from "@/utils/cache.js";
import { tips } from "../../utils/tips";
export default {
  namespaced: true,
  state: {
    token: LocalCache.getCache("token"),
    userName: LocalCache.getCache("userName"),
    roleIds: LocalCache.getCache("roleIds"),
  },
  mutations: {
    //编辑tab切换页面功能
    setinfoData(state, data) {
      state.token = data.token || "";
      state.userName = data.userName || "";
      state.roleIds = data.roleIds || [];
    },
  },
  actions: {
    async login(context, payload) {
      try {
        let res = await LoginAPi.login(payload);
        console.log(res, "ressssssssssssss");
        if (res.success) {
          LocalCache.setCache("token", res.data.token);
          LocalCache.setCache("userName", res.data.userName);
          LocalCache.setCache("roleIds", res.data.roleIds);
          context.commit("setinfoData", res.data);
        }
        tips(res, "登录");
        return res;
      } catch (err) {
        console.log(err);
      }
    },
    loginout(context) {
      LocalCache.deleteCache("token");
      LocalCache.deleteCache("userName");
      context.commit("setinfoData", {});
    },
  },
};

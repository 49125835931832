<template>
  <div>
    <div class="top-container search-box" style="height: 135px">
      <div class="lable-box search-item">
        <label> 商品名称：</label>
        <a-input v-model="searchText.goodsNameCn" placeholder="请输入" />
      </div>
      <div class="lable-box search-item" style="height: 32px">
        <label> 商品分类：</label>
        <selectTree
          ref="selectThre"
          :treeData="treeData"
          v-model="searchText.goodsCategoryId"
        />
      </div>
      <div class="lable-box search-item">
        <label>工厂名称：</label>
        <a-input v-model="searchText.factoryName" placeholder="请输入" />
      </div>
      <div class="lable-box search-item" style="height: 32px">
        <label>状态 ：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
        <a-select
          show-search
          placeholder="请选择"
          :default-active-first-option="false"
          :style="{ width: '28.5rem' }"
          :not-found-content="null"
          v-model="searchText.state"
        >
          <a-select-option
            v-for="(item, i) in stateList"
            :value="item.value"
            :key="i"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="lable-box search-item" style="height: 32px">
        <label>支持业务 ：</label>
        <a-select
          show-search
          placeholder="请选择"
          :default-active-first-option="false"
          :style="{ width: '28.5rem' }"
          :not-found-content="null"
          v-model="searchText.supportBusiness"
        >
          <a-select-option
            v-for="(item, i) in supportBusinessList"
            :value="item.value"
            :key="i"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="search-item">
        <a-button class="button-item" @click="resetData"> 重置 </a-button>
        <a-button class="button-item" @click="search"> 查询 </a-button>
      </div>
    </div>
    <div class="bottom-container">
      <div>
        <a-button
          style="background: #ff4600; color: #fff; margin: 10px 0px 0px 10px"
          @click="showAddModel"
        >
          新增
        </a-button>
      </div>
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        :scroll="{ y: 375 }"
      >
      <template slot="supportBusiness" slot-scope="text,record">
        <span>{{  record.customized?'定制':''}}</span>
        <span v-if="record.customized&&record.inventory">,</span>
        <span>{{  record.inventory?'库存处理':''}}</span>
        </template>
        <template slot="option" slot-scope="text, record">
          <div v-if="record.state == 1">
            <span
              @click="handleEdit(record)"
              style="color: rgb(255, 70, 0); cursor: pointer"
            >
              编辑
            </span>
            <span
              @click="deletaModel(record)"
              style="color: rgb(255, 70, 0); cursor: pointer"
            >
              删除
            </span>
          </div>
          <div v-if="record.state == 0">
            <span
              @click="handleDeteil(record)"
              style="color: rgb(255, 70, 0); cursor: pointer"
            >
              详情
            </span>
            <span
              @click="downModel(record)"
              style="color: rgb(255, 70, 0); cursor: pointer"
            >
              下架
            </span>
          </div>
          <div v-if="record.state == 2">
            <span
              @click="handleDeteil(record)"
              style="color: rgb(255, 70, 0); cursor: pointer"
            >
              详情
            </span>
            <span
              @click="downModel(record)"
              style="color: rgb(255, 70, 0); cursor: pointer"
            >
              下架
            </span>
          </div>
          <div v-if="record.state == 3">
            <span
              @click="handleDeteil(record)"
              style="color: rgb(255, 70, 0); cursor: pointer"
            >
              详情
            </span>
          </div>
          <div v-if="record.state == 4">
            <span
              @click="handleDeteil(record)"
              style="color: #007af8; cursor: pointer"
            >
              详情
            </span>
          </div>
          <div v-if="record.state == 5">
            <span
              @click="handleEdit(record)"
              style="color: #007af8; cursor: pointer"
            >
              编辑
            </span>
          </div>
        </template>
        <template slot="customStatus" slot-scope="text">
          <span
            v-if="text === 0"
            :style="{ color: getColor(getMenuName(stateList, text)) }"
            >{{ getMenuName(stateList, text) }}</span
          >
          <span
            v-if="text === 1"
            :style="{ color: getColor(getMenuName(stateList, text)) }"
            >{{ getMenuName(stateList, text) }}</span
          >
          <span
            v-if="text === 2"
            :style="{ color: getColor(getMenuName(stateList, text)) }"
            >{{ getMenuName(stateList, text) }}</span
          >
          <span
            v-if="text === 3"
            :style="{ color: getColor(getMenuName(stateList, text)) }"
            >{{ getMenuName(stateList, text) }}</span
          >
          <span
            v-if="text === 4"
            :style="{ color: getColor(getMenuName(stateList, text)) }"
            >{{ getMenuName(stateList, text) }}</span
          >
          <span
            v-if="text === 5"
            :style="{ color: getColor(getMenuName(stateList, text)) }"
            >{{ getMenuName(stateList, text) }}</span
          >
        </template>
        <template slot="factoryName" slot-scope="text, record">
          <div
            @click="handleName(record)"
            style="cursor: pointer; color: rgb(255, 70, 0)"
          >
            {{ text }}
          </div>
        </template>
      </a-table>
    </div>
    <addTableModel :config="addConfig" v-if="addConfig.visible" ref="addModel">
      <template #titleText>
        <h3 style="margin-top: 1rem">是否检测：</h3>
        <a-radio-group v-model="isDetect">
          <a-radio :value="true"> 是 </a-radio>
          <a-radio :value="false"> 否 </a-radio>
        </a-radio-group>
      </template>
      <template #footer>
        <div style="text-align: right">
          <a-button @click="closeAdd" style="margin: 0 10px">返回</a-button>
          <a-button @click="handleSave" style="margin: 0 10px">暂存</a-button>
          <a-popconfirm
            title="是否确定提交?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm"
          >
            <a-button class="xp-btn-color">提交</a-button>
          </a-popconfirm>
        </div>
      </template>
    </addTableModel>
    <addTableModel :config="editConfig" v-if="editConfig.visible">
      <template #titleText>
        <h3 style="margin-top: 1rem">是否检测：</h3>
        <a-radio-group v-model="isDetect" disabled>
          <a-radio :value="true"> 是 </a-radio>
          <a-radio :value="false"> 否 </a-radio>
        </a-radio-group>
      </template>
      <template #footer>
        <div style="text-align: right">
          <a-button @click="closEdit" style="margin: 0 10px">返回</a-button>
        </div>
      </template>
    </addTableModel>
    <confimModel :config="confirmConfig" @compile="getBaseData" />
    <detailModel :config="detailConfig" v-if="detailConfig.visible" />
  </div>
</template>

<script>
import addTableModel from "@/components/addTableModel/addTableModel.vue";
import confimModel from "@/components/confimModel/index.vue";
import { shelfApplicaAPi } from "@/api/ShelfApplication.js";
import { setKey } from "@/utils/utils.js";
import pagination from "@/mixins/Pagination.js";
import { tips } from "../../utils/tips";
import selectTree from "@/components/selectTree";
import { SelectionGoods } from "@/api/SelectionGoods.js";
// import { factoryApi } from "@/api/FactoryQuery.js";
import detailModel from "./component/detailModel.vue";
import { getColor } from "@/utils/color.js";
import { getMenuName } from "@/utils/utils.js";
const columns = [
  {
    dataIndex: "goodsNameCn",
    key: "goodsNameCn",
    title: "商品名称",
    ellipsis: true,
    width: 150,
  },
  {
    title: "商品分类",
    ellipsis: true,
    dataIndex: "goodsCategoryName",
    key: "goodsCategoryName",
  },
  {
    title: "支持业务",
    ellipsis: true,
    dataIndex: "supportBusiness",
    key: "supportBusiness",
    scopedSlots: { customRender: "supportBusiness" },
    width:150
  },
  {
    title: "申请时间",
    ellipsis: true,
    dataIndex: "createTime",
    key: "createTime",
  },
  // {
  //   title: "出厂时间",
  //   key: "updateTime11",
  //   dataIndex: "updateTime",
  // },
  {
    title: "基础价",
    ellipsis: true,
    dataIndex: "onPrice",
  },
  {
    title: "工厂价",
    ellipsis: true,
    dataIndex: "supplyPrice",
  },
  {
    title: "定制起订量",
    ellipsis: true,
    dataIndex: "minOrder",
  },
  {
    title: "库存起批量",
    ellipsis: true,
    dataIndex: "minInventory",
  },
  {
    title: "可售区域",
    dataIndex: "availableArea",
    ellipsis: true,
    width: 100,
  },
  {
    title: "工厂名称",
    ellipsis: true,
    dataIndex: "factoryName",
    scopedSlots: { customRender: "factoryName" },
    width: 200,
  },
  {
    title: "状态",
    ellipsis: true,
    dataIndex: "state",
    scopedSlots: { customRender: "customStatus" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "option" },
    // width: 100,
  },
];
const data = [];
export default {
  mixins: [pagination],
  data() {
    return {
      data,
      columns,
      addConfig: {
        visible: false,
      },
      detailConfig: {
        visible: false,
      },
      editConfig: {
        visible: false,
      },
      confirmConfig: {
        visible: false,
        title: "是否要下架该产品",
      },
      searchText: {
        goodsNameCn: "",
        goodsCategoryId: "",
        factoryName: "",
        state: undefined,
      },
      treeData: [],
      stateList: [
        { name: "已上架", value: 0 },
        { name: "已下架", value: 1 },
        { name: "已禁用", value: 2 },
        { name: "已退回", value: 3 },
        { name: "待审核", value: 4 },
        { name: "暂存", value: 5 },
      ],
      supportBusinessList:[
        {name:'定制',value:'true'},
        {name:'库存处理',value:'false'},
      ],
      isDetect: false, // 是否检测
    };
  },
  components: {
    addTableModel,
    confimModel,
    detailModel,
    selectTree,
  },
  methods: {
    getColor,
    getMenuName,
    // 审核不需要编辑
    async handleDeteil(data) {
      let target = {
        GoodsId: data.goodsId,
      };
      let res = await shelfApplicaAPi.geyEditData(target);
      this.isDetect = res.data.isDetect;
      if (res.success) {
        let obj = {
          visible: true,
          data: res.data,
          type: "uninput",
          title: "详情",
        };
        this.editConfig = obj;
      }
    },
    showAddModel() {
      let obj = {
        visible: true,
        type: "input",
      };
      this.addConfig = obj;
    },
    closeAdd() {
      this.addConfig.visible = false;
    },

    // 处理暂存
    async handleSave() {
      let res = await this.$refs.addModel.handleTempSave();
      let result = await shelfApplicaAPi.temporaryData(res.target);
      if (result.success) {
        this.addConfig.visible = false;
        this.getBaseData();
      }

      tips(result, "暂存");
    },

    async confirm() {
      let res = await this.$refs.addModel.handleOk();
      if (!res.isok) return;
      res.target.inventory=res.target.inventory?res.target.inventory:false
      res.target.customized=res.target.customized?res.target.customized:false
      // 校验通过
      if (this.addConfig.type === "edit") {
        res.target.goodsId = this.addConfig.GoodsId;
        res.target.isDetect = this.isDetect; // 在最外层赋值是否检测的值
        let res2 = await shelfApplicaAPi.editGoodData(res.target);
        if (res2.success) {
          this.addConfig.visible = false;
          // this.pagination.page = 1;
          this.getBaseData();
          // this.resetData();
        }

        tips(res2, "编辑");
      } else {
        res.target.isDetect = this.isDetect;
        let res2 = await shelfApplicaAPi.addGoodData(res.target);
        if (res2.success) {
          this.addConfig.visible = false;
          // this.getBaseData();
          this.resetData();
        }
        tips(res2, "新增");
      }
    },
    deletaModel(data) {
      let obj = {
        url: `/mall/factory/deleteById/${data.goodsId}`,
        title: `确认要删除“${data.goodsNameCn}”吗？`,
        visible: true,
      };
      this.confirmConfig = obj;
    },
    async getBaseData() {
      this.loading = true;
      let baseParmase = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.searchText,
      };
      try {
        let res = await shelfApplicaAPi.getBaseData(baseParmase);
        if (this.pagination.current > 1 && res.data.records.length == 0) {
          this.pagination.current -= 1;
          this.getBaseData();
        }
        this.pagination.total = res.data.total;
        this.data = setKey(res.data.records, "goodsId");
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getBaseData();
    },
    onPage(e) {
      this.pagination.current = e;
      this.getBaseData();
    },
    resetData() {
      this.searchText = {
        factoryName: "", //工厂名称
        factoryAddress: "", //工厂地址
        factoryLinkman: "", //工厂联系人
        factoryPhonenumber: "", //联系电话
      };
      this.$refs.selectThre.reset();
      this.pagination.current = 1;
      this.getBaseData();
    },
    search() {
      this.pagination.current = 1;
      this.getBaseData();
    },
    downModel(data) {
      let obj = {
        url: `/mall/factory/soldPut/${data.goodsId}`,
        visible: true,
        method: "put",
        title: `确认要下架“${data.goodsNameCn}”吗？`,
        message: "下架成功",
      };
      this.confirmConfig = obj;
    },
    closEdit() {
      this.editConfig.visible = false;
    },
    async handleEdit(data) {
      let target = {
        GoodsId: data.goodsId,
      };
      let res = await shelfApplicaAPi.geyEditData(target);
      if (res.success) {
        let obj = {
          visible: true,
          data: res.data,
          type: "edit",
          title: "编辑",
          GoodsId: data.goodsId,
        };
        this.addConfig = obj;
      }
    },
    async handleName(item) {
      let res = await shelfApplicaAPi.getDetail(item.tenantId);
      if (res.success) {
        this.detailConfig.visible = true;
        this.detailConfig.data = res.data;
      }
    },
    async getCateDate() {
      let res = await SelectionGoods.getCateGoryData();
      if (res.success) {
        this.addParams(res.data);
        this.treeData = res.data;
      }
    },
    addParams(arr) {
      arr.forEach((v) => {
        v.key = v.id;
        v.title = v.label;
        v.value = v.id;
        if (v.children && v.children.length) {
          this.addParams(v.children);
        }
      });
    },
  },
  mounted() {
    this.getBaseData();
    this.getCateDate();
  },
};
</script>

<style scoped lang="scss">
.search-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .search-item {
    width: 26%;
    text-align: right;
    .button-item {
      margin-left: 10px;
      &:nth-child(2) {
        background-color: #ff4600;
        color: #fff;
      }
    }
  }
}
.ant-table-wrapper {
  margin-top: 20px;
  /deep/.ant-table-thead > tr > th {
    background-color: #ffe9db;
  }
}
</style>

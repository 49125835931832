export default {
  data() {
    return {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
      pagination: {
        onChange: (current) => this.onPage(current),
        onShowSizeChange: (current, size) => this.onSizeChange(current, size),
        pageSize: 10,
        current: 1,
        // showQuickJumper: false,
        showSizeChanger: true,
        showQuickJumper: false,
        pageSizeOptions: ["10", "20", "30"],
        total: 0,
      },
    };
  },
  methods: {
    /* eslint-disable */
    //分页
    onSearch() {
      this.pagination.current = 1;
      
      if (typeof this.getSourceData === "function") {
        this.getSourceData();
      }
    },
    onPage(current) {
      this.pagination.current = current;
      if (typeof this.getSourceData === "function") {
		  console.log("dfasfasd");
        this.getSourceData();
      }
    },
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      if (typeof this.getSourceData === "function") {
        this.getSourceData();
      }
    },
    showConfirm(func, title = "删除确认", content = "是否确认删除此记录？") {
      this.$confirm({
        title,
        content,
        cancelText: "取消",
        okText: "确认",
        onOk() {
          func();
        },
        onCancel() {
          console.log("取消删除");
        },
      });
    },
    // 模糊查询
    queryBtn() {
      console.log("dasfasdfasdf");
      this.pagination.current = 1;
      this.getSourceData();
    },
  },
};

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const Ptype = {
	setPicker: 'setPickers',
	increment: 'increment',
}

export default {
	namespaced: true,
	state: {
		postList: [],//岗位
		deplist: [], //部门
		roleList: [], //角色
		statusList: [
			{ 'name': "禁用", 'id': '0' },
			{ 'name': "启用", 'id': '1' },
			{ 'name': "在线", 'id': '2' },
			{ 'name': "下线", 'id': '3' },
		],
		staffList:[],
		buttonSize:'small',
	},
	mutations: {
		[Ptype.setPicker](state, payload) {
			Object.keys(payload).forEach(key => {
				state[key] = payload[key]
			})
		},
		setButtonSize(state , payload){
			state.buttonSize =payload
		},
	},
	actions: {
		[Ptype.increment]({ commit }, payload) {
			commit('setPickers', payload)
		}
	},

}

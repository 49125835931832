<template>
  <div>
    <a-modal
      title="工厂详情"
      :visible="config.visible"
      @cancel="handleCancel"
      :footer="null"
      width="854px"
    >
      <div class="detail-box">
        <div class="left">
          <div class="left-item">
            <span>工厂名称:&nbsp; </span
            ><span class="content">{{ config.data.factoryName }}</span>
          </div>
          <div class="left-item">
            <span>工厂地址:&nbsp; </span
            ><span class="content">{{ config.data.factoryAddress }}</span>
          </div>
          <div class="left-item">
            <span>工厂联系人:&nbsp; </span
            ><span class="content">{{ config.data.factoryLinkman }}</span>
          </div>
          <div class="left-item" style="display: flex">
            <span>营业执照:&nbsp; </span>
            <!-- <img class="imageLicense" :src="config.data.factoryLicense[0]" /> -->
            <viewer :image="config.data.factoryLicense[0]">
              <img
                class="imageLicense"
                :key="1"
                :src="config.data.factoryLicense[0]"
              />
            </viewer>
          </div>
        </div>
        <div class="right-box">
          <div class="right-item">
            <span>统一社会信用社代码:&nbsp;</span
            ><span class="content">{{ config.data.factoryCreditCode }}</span>
          </div>
          <div class="right-item">
            <span>经营范围:&nbsp;</span
            ><span class="content">{{ config.data.manageScope }}</span>
          </div>
          <div class="right-item">
            <span>联系电话:&nbsp;</span
            ><span class="content">{{ config.data.factoryPhonenumber }}</span>
          </div>
          <div class="right-item">
            <span>是否支持一件代发:&nbsp; </span>
            <span class="content">
              <a-radio-group :value="config.data.ifsend">
                <a-radio :value="true"> 是 </a-radio>
                <a-radio :value="false"> 否 </a-radio>
              </a-radio-group>
            </span>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px; text-align: right">
        <a-button @click="handleCancel"> 关闭 </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
          data: {}, //模态框数据
        };
      },
    },
  },
  methods: {
    handleCancel() {
      this.config.visible = false;
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.detail-box {
  display: flex;
  height: 205px;
  justify-content: space-around;
  .left {
    width: 50%;
  }
  .right-box {
    width: 50%;
  }
  .right-box-item,
  .left-item {
    min-height: 30px;
    line-height: 30px;
    display: flex;
    span:nth-child(1) {
      display: block;
      width: 100px;
      text-align: right;
      color: #999999;
    }
    .imageLicense {
      width: 110px;

      vertical-align: text-top;
      margin-bottom: 10px;
    }
  }
  .right-item {
    min-height: 30px;
    line-height: 30px;
    display: flex;
    // span:nth-child(2) {
    //   flex: 1;
    // }
    span:nth-child(1) {
      display: block;
      width: 137px;
      text-align: right;
      font-size: 14px;
      color: #999999;
    }
    .title {
      width: 181px;
    }
  }
  .emplity {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .content {
    flex: 1;
  }
}
</style>

<template>
  <div>
    <a-modal
      title="验厂"
      :visible="config.visible"
      @cancel="handleCancel"
      width="900px"
      @ok="handleOk"
      ok-text="保存"
      cancel-text="取消"
    >
      <a-form-model ref="ruleForm" :model="factoryForm" :rules="factoryRules">
        <div class="flex">
          <a-form-model-item label="工厂名称">
            <a-input
              placeholder="请输入"
              v-model="factoryForm.factoryName"
              :disabled="true"
            />
          </a-form-model-item>

          <a-form-model-item label="标签">
            <a-select
              show-search
              v-model="factoryForm.factoryTag"
              placeholder="请选择"
              mode="multiple"
              allowClear
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                v-for="(item, index) in factoryOption"
                :key="index"
                :value="item"
                :disabled="
                  factoryForm.factoryTag && factoryForm.factoryTag.length >= 5
                "
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </div>
        <div class="flex">
          <a-form-model-item label="工厂营销负责人" prop="factoryMarketingName">
            <a-input
              placeholder="请输入"
              v-model="factoryForm.factoryMarketingName"
              :maxLength="30"
            />
          </a-form-model-item>
          <a-form-model-item
            label="联系方式"
            prop="factoryMarketingPhonenumber"
          >
            <a-input
              class="factory-check"
              placeholder="请输入"
              v-model="factoryForm.factoryMarketingPhonenumber"
              :maxLength="30"
            />
            <a-checkbox v-model="factoryForm.ifwechatnumber"
              >微信同号</a-checkbox
            >
          </a-form-model-item>
        </div>
        <a-form-model-item label="主营产品" prop="manageScope">
          <a-textarea
            v-model="factoryForm.manageScope"
            placeholder="请输入"
            :maxLength="200"
          />
        </a-form-model-item>
        <a-form-model-item label="企业介绍" prop="factoryIntroduce">
          <a-textarea
            v-model="factoryForm.factoryIntroduce"
            placeholder="请输入"
            :maxLength="500"
            style="height: 100px"
          />
        </a-form-model-item>
        <a-form-model-item label="电子产品册" prop="productManual">
          <UploadFile
            v-model="factoryForm.productManual"
            :config="fileFactoryConfig"
          >
            <template #tip>
              <span style="opacity: 0.65"
                >支持上传文件：excel、pdf、ppt；最大限制50M</span
              >
            </template>
          </UploadFile>
        </a-form-model-item>
        <div class="flex">
          <a-form-model-item prop="facadeVedio">
            <span slot="label">
              主页视频&nbsp;
              <a-tooltip
                title="支持上传.mp4  .avi 的视频文件，大小限制为100M以内"
              >
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <uploadVideo
              v-model="factoryForm.facadeVedio"
              :config="fileFactoryVideo"
            >
            </uploadVideo>
          </a-form-model-item>
          <a-form-model-item prop="factoryMainDiagram">
            <span slot="label">
              工厂主图&nbsp;
              <a-tooltip
                title="支持上传png、jpg、jpeg格式的图片，大小限制为10M以内"
              >
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <UploadImage
              v-model="factoryForm.factoryMainDiagram"
              :config="factoryConfig"
            >
            </UploadImage>
          </a-form-model-item>
        </div>
        <a-form-model-item prop="cloudFactory" class="factory-table">
          <span slot="label">
            云上工厂&nbsp;
            <a-tooltip>
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-table
            :columns="columns"
            :data-source="factoryForm.cloudFactory"
            :pagination="false"
          >
            <span slot="name_1"><span class="require">*</span>显示名称</span>
            <span slot="fileType_1"><span class="require">*</span>类型</span>
            <span slot="file_1"><span class="require">*</span>配置</span>
            <template slot="name" slot-scope="text, record">
              <div v-if="record.isServse">{{ record.name }}</div>
              <a-input v-model="record.name" :maxLength="10" v-else />
            </template>
            <template slot="fileType" slot-scope="text, record, index">
              <div v-if="record.isServse">
                {{ fileNum[record.fileType] }}
              </div>
              <a-select
                v-else
                v-model="record.fileType"
                :getPopupContainer="
                  (triggerNode) => handleDislocation(triggerNode)
                "
                :default-active-first-option="false"
                :not-found-content="null"
                @change="changeType(record, index)"
              >
                <a-select-option
                  v-for="item in fileType"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </template>
            <template slot="file" slot-scope="text, record">
              <a-tooltip
                title="支持上传png、jpg、jpeg、图片大小限制为10M以内，或 .mp4  .avi的视频文件，大小限制为100M以内"
              >
                <UploadImage
                  v-if="record.fileType === 0"
                  v-model="record.file"
                  :config="factoryImage"
                >
                </UploadImage>
                <uploadVideo
                  v-if="record.fileType === 1"
                  v-model="record.file"
                  :config="fileFactoryVideo"
                ></uploadVideo>
              </a-tooltip>
            </template>
            <template slot="operate" slot-scope="text, record, index">
              <span
                class="opertation"
                @click="addFactory('云上工厂')"
                v-if="record.isServse"
                >添加</span
              >
              <span
                class="opertation"
                @click="serveFactory('云上工厂', record, index)"
                v-if="!record.isServse"
                >保存</span
              >
              <span
                class="opertation"
                v-else
                @click="editFactory('云上工厂', record, index)"
                >编辑</span
              >
              <span
                class="opertation"
                @click="delFactory('云上工厂', index, record)"
                v-if="factoryForm.cloudFactory.length > 1"
                >删除</span
              >
              <span
                class="opertation"
                @click="canFactory('云上工厂', record, index)"
                v-if="!record.isServse"
                >取消</span
              >
            </template></a-table
          >
        </a-form-model-item>
        <a-form-model-item label="工厂资质" prop="factoryQualification">
          <span slot="label">
            工厂资质&nbsp;
            <a-tooltip
              title="支持上传png、jpg、jpeg格式的图片，大小限制为10M以内"
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-table
            :columns="qualificatColumns"
            :data-source="factoryForm.factoryQualification"
            :pagination="false"
          >
            <span slot="name_1"><span class="require">*</span>资质名称</span>
            <span slot="file_1"><span class="require">*</span>文件</span>
            <template slot="name" slot-scope="text, record">
              <div v-if="record.isServse">{{ record.name }}</div>
              <a-input v-model="record.name" :maxLength="10" v-else />
            </template>
            <template slot="file" slot-scope="text, record">
              <a-tooltip
                title="支持上传png、jpg、jpeg格式的图片，图片大小限制为10M以内"
              >
                <UploadImage
                  v-model="record.file"
                  :config="factoryConfig"
                  ref="uploadImage"
                ></UploadImage>
              </a-tooltip>
            </template>
            <template slot="operate" slot-scope="text, record, index">
              <span
                class="opertation"
                @click="addFactory('工厂资质')"
                v-if="record.isServse"
                >添加</span
              >
              <span
                class="opertation"
                @click="serveFactory('工厂资质', record, index)"
                v-if="!record.isServse"
                >保存</span
              >
              <span
                class="opertation"
                v-if="record.isServse"
                @click="editFactory('工厂资质', record, index)"
                >编辑</span
              >
              <span
                class="opertation"
                @click="delFactory('工厂资质', index)"
                v-if="factoryForm.factoryQualification.length > 1"
                >删除</span
              >
              <span
                class="opertation"
                @click="canFactory('工厂资质', record, index)"
                v-if="record.isEdit"
                >取消</span
              >
            </template>
          </a-table>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "name",
    key: "name",
    width: 200,
    slots: { title: "name_1" },
    scopedSlots: { customRender: "name" },
  },
  {
    dataIndex: "fileType",
    key: "fileType",
    width: 200,
    slots: { title: "fileType_1" },
    scopedSlots: { customRender: "fileType" },
  },
  {
    dataIndex: "file",
    key: "file",
    width: 200,
    slots: { title: "file_1" },
    scopedSlots: { customRender: "file" },
  },
  {
    title: "操作",
    dataIndex: "operate",
    key: "operate",
    width: 200,
    scopedSlots: { customRender: "operate" },
  },
]; //云上工厂
const qualificatColumns = [
  {
    dataIndex: "name",
    key: "name",
    width: 200,
    slots: { title: "name_1" },
    scopedSlots: { customRender: "name" },
  },
  {
    dataIndex: "file",
    key: "file",
    width: 200,
    slots: { title: "file_1" },
    scopedSlots: { customRender: "file" },
  },
  {
    title: "操作",
    dataIndex: "operate",
    key: "operate",
    scopedSlots: { customRender: "operate" },
  },
]; //工厂资质
import { factoryRules } from "@/utils/rules.js";
import mixins from "@/mixins/mixins.js";
import UploadImage from "@/components/uploadImage";
import UploadFile from "@/components/uploadFile";
import uploadVideo from "@/components/uploadVideo";
import { factoryApi } from "@/api/FactoryQuery";
export default {
  mixins: [mixins],
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
        };
      },
    },
  },
  components: {
    UploadFile,
    UploadImage,
    uploadVideo,
  },
  data() {
    return {
      factoryRules,
      columns,
      qualificatColumns,
      fileType: [
        { label: "图片", value: 0 },
        { label: "视频", value: 1 },
      ],
      fileNum: {
        0: "图片",
        1: "视频",
      },
      factoryForm: {
        cloudFactory: [
          {
            file: "", //文件地址
            fileType: null, //文件类型
            name: null, //云上工厂显示名称
            isServse: false,
            isEdit: false,
          },
        ], //云上工厂
        facadeVedio: null, //门面视频
        factoryId: null, //工厂id
        factoryIntroduce: null, //企业介绍
        factoryMainDiagram: "", //工厂主图
        factoryMarketingName: null, //工厂营销负责人
        factoryMarketingPhonenumber: null, //工厂营销负责人联系电话
        factoryName: null, //工厂名称
        factoryQualification: [
          {
            file: "", //资质图片地址
            name: null, //资质名称
          },
        ], //工厂资质
        factoryTag: null, //工厂标签
        ifwechatnumber: true, //是否微信同号
        manageScope: null, //经营范围
        productManual: null, //电子产品册
      },
      factoryConfig: {
        maxLength: 1, //1张
        maxSize: 10, //20M
      },
      fileFactoryConfig: {
        maxLength: 1,
        maxSize: 50, //50M
        fileType: [".xls", ".pdf", ".ppt", "xlsx"],
      },
      factoryImage: {
        maxLength: 1, //1张
        maxSize: 10, //20M
      },
      fileFactoryVideo: {
        maxLength: 1,
        maxSize: 100, //100M
      },
      factoryOption: [
        "高新技术企业",
        "数字化工厂高新技术企业",
        "数字化工厂",
        "独立品牌",
        "单项冠军",
        "科技领军企业",
        "省市级技术研发中心",
        "国家火炬计划",
        "行业十强",
        "制造业企业500强",
        "专精特新“小巨人”",
        "上市企业",
        "外贸出口",
      ], //标签
      factoryRow: {}, //编辑时，表格中整行的数据
      isServse: true,
      isEdit: false,
      cloudIndex: "1",
      QualificatIndex: "",
    };
  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate(async (res) => {
        if (res) {
          if (this.handelServe(this.factoryForm)) {
            this.factoryForm.cloudFactory.map((item) => {
              if (typeof item.file === "object") {
                item.file = item.file[0];
              }
            });
            this.factoryForm.factoryTag =
              this.factoryForm.factoryTag?.toString();
            if (typeof this.factoryForm.factoryMainDiagram === "object") {
              this.factoryForm.factoryMainDiagram =
                this.factoryForm.factoryMainDiagram.toString();
            }
            const factoryInspectionDTO = {
              ...this.factoryForm,
              facadeVedio: this.factoryForm.facadeVedio[0],
              factoryId: this.config.data.factoryId,
            };
            let result = await factoryApi.createInspection(
              factoryInspectionDTO
            );
            if (result.success) {
              this.handleCancel();
              this.$message.success("保存成功");
              this.$parent.pagination.page = 1;
              this.$parent.resetData();
            }
          }
        }
      });
    },
    handelServe(factoryForm) {
      let target = true;
      factoryForm.cloudFactory.map((item) => {
        if (!item.name) {
          target = false;
          return this.$message.warn("请填写名称");
        }
        if (!item.file) {
          target = false;
          return this.$message.warn("请上次文件");
        }
        if (item.fileType !== 0 && item.fileType !== 1) {
          target = false;
          return this.$message.warn("请填写文件类型");
        }
      });
      factoryForm.factoryQualification.map((item) => {
        if (!item.name) {
          target = false;
          return this.$message.warn("请填写名称");
        }
        if (!item.file) {
          target = false;
          return this.$message.warn("请上次文件");
        }
      });
      return target;
    },
    handleCancel() {
      this.config.visible = false;
      this.$emit("configData", {});
      this.config.type === "新增"
        ? this.$emit("configData", this.factoryForm)
        : this.$emit("configData", {
            cloudFactory: [
              {
                file: "", //文件地址
                fileType: null, //文件类型
                name: null, //云上工厂显示名称
                isServse: false,
                isEdit: false,
              },
            ],
            factoryQualification: [
              {
                file: "", //资质图片地址
                name: null, //资质名称
              },
            ],
          });
    },
    changeType(record, index) {
      if (record.fileType === 1) {
        this.$set(this.factoryForm.cloudFactory, index, {
          ...record,
          file: "",
        });
      } else {
        this.$set(this.factoryForm.cloudFactory, index, {
          ...record,
          file: [],
        });
      }
    },
    addFactory(type) {
      if (type === "云上工厂") {
        return this.factoryForm.cloudFactory.length > 20
          ? this.$message.warn("当前字段最多支持20条数据！")
          : this.factoryForm.cloudFactory.push({
              file: "",
              fileType: 0,
              name: null,
              isServse: false,
              isEdit: false,
            });
      } else {
        return this.factoryForm.factoryQualification.length > 20
          ? this.$message.warn("当前字段最多支持20条数据！")
          : this.factoryForm.factoryQualification.push({
              file: "",
              name: null,
              isServse: false,
              isEdit: false,
            });
      }
    },
    serveFactory(type, record, index) {
      if (!record.name) {
        return this.$message.warn("请填写名称");
      }
      if (!record.file) {
        return this.$message.warn("请上次文件");
      }
      if (type === "云上工厂") {
        if (record.fileType !== 0 && record.fileType !== 1) {
          return this.$message.warn("请选择文件类型");
        }
        this.$set(this.factoryForm.cloudFactory, index, {
          ...record,
          isEdit: false,
          isServse: true,
        });
      } else {
        this.$set(this.factoryForm.factoryQualification, index, {
          ...record,
          isEdit: false,
          isServse: true,
        });
      }
    },
    editFactory(type, record, index) {
      if (type === "云上工厂") {
        this.$set(this.factoryForm.cloudFactory, index, {
          ...record,
          isEdit: true,
          isServse: false,
        });
      } else {
        this.$set(this.factoryForm.factoryQualification, index, {
          ...record,
          isEdit: true,
          isServse: false,
        });
      }
      this.$set(this.factoryRow, 0, {
        ...record,
      });
    },
    async delFactory(type, index) {
      if (type === "云上工厂") {
        return this.factoryForm.cloudFactory.splice(index, 1);
      } else {
        return this.factoryForm.factoryQualification.splice(index, 1);
      }
    },
    canFactory(type, record, index) {
      if (type === "云上工厂") {
        this.$set(this.factoryForm.cloudFactory, index, {
          ...record,
          isEdit: false,
          isServse: true,
        });
      } else {
        this.$set(this.factoryForm.factoryQualification, index, {
          ...record,
          isEdit: false,
          isServse: true,
        });
      }
      record.name = this.factoryRow.name;
    },
    handleDeteil() {
      // 如果为更新，在此处处理数据,uploadVideo
      this.factoryForm.facadeVedio = [this.config.data?.facadeVedio];
      this.factoryForm.cloudFactory?.map((item, index) => {
        this.factoryForm.cloudFactory[index].file =
          item.fileType === 1 ? [item.file] : item.file;
      });
    },
  },
  created() {
    this.factoryForm = this.config.data;
    if (
      typeof this.factoryForm.factoryTag === "string" &&
      this.factoryForm.factoryTag
    ) {
      this.factoryForm.factoryTag = this.factoryForm.factoryTag?.split(",");
    }
    if (this.config.type === "更新") {
      if (!this.factoryForm.factoryTag) {
        this.factoryForm.factoryTag = undefined;
      }
      return this.handleDeteil();
    }
  },
};
</script>
<style lang="scss" scoped>
.factory-check {
  width: 319px;
  margin-right: 10px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/.ant-form-item {
    width: 49%;
  }
}
.opertation {
  margin-right: 10px;
  color: rgb(255, 70, 0);
}
.require {
  color: rgb(255, 70, 0);
}
</style>

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const type = {
  SetTabList: "SetTabList",
  AsynSetTabList: "AsynSetTabList",
  SetActiveKey: "SetActiveKey",
  SetCurrent: "SetCurrent",
  SetRouterBase: "SetRouterBase",
};

export default {
  namespaced: true,
  state: {
    SiderMenuList: [
      {
        name: "工厂管理",
        icon: "gc",
        pre: 0, // 前面有几个
        id: 1,
        children: [
          // {
          //   name: "工厂查询",
          //   path: "/factoryQuery",
          //   icon: "solution",
          // },
          // {
          //   name: "上架申请",
          //   path: "/shelfApplication",
          //   icon: "solution",
          // },
          // {
          //   name: "选品上架",
          //   path: "/selectiongoods",
          //   icon: "solution",
          // },
          // {
          //   name: "评价体系",
          //   path: "/evaluationSystem",
          //   icon: "solution",
          // },
          // {
          //   name: "工厂评价",
          //   path: "/evaluationFactory",
          //   icon: "solution",
          // },
          // {
          //   name: "延期申请",
          //   path: "/extensionApply",
          //   icon: "solution",
          // },
        ],
      },
      {
        name: "商户管理",
        icon: "sh",
        id: 2,
        children: [
          // {
          //   name: "商户查询",
          //   path: "/merchantQuery",
          //   icon: "solution",
          // },
          // {
          //   name: "订单审核",
          //   path: "/orderreview",
          //   icon: "solution",
          // },
          // {
          //   name: "延期审核",
          //   path: "/extensionExamine",
          //   icon: "solution",
          // },
        ],
      },
      {
        name: "门户管理",
        icon: "mh",
        id: 3,
        children: [
          // {
          //   name: "广告管理",
          //   path: "/advertisementManage",
          //   icon: "team",
          // },
          // {
          //   name: "爆品管理",
          //   path: "/explosiveManage",
          //   icon: "key",
          // },
          // {
          //   name: "新品管理",
          //   path: "/newManage",
          //   icon: "key",
          // },
          // {
          //   name: "留言管理",
          //   path: "/leavingManage",
          //   icon: "key",
          // },
        ],
      },
      {
        name: "统计分析",
        icon: "tj",
        id: 4,
        children: [
          // {
          //   name: "商户分析",
          //   path: "/merchantAnalysis",
          //   icon: "setting",
          // },
          // {
          //   name: "选品分析",
          //   path: "/selectionAnalysis",
          //   icon: "setting",
          // },
          // {
          //   name: "搜索统计",
          //   path: "/searchStatistics",
          //   icon: "setting",
          // },
        ],
      },
      {
        name: "系统管理",
        icon: "xt",
        id: 5,
        children: [
          // {
          //   name: "选品分类",
          //   path: "/classification",
          //   icon: "setting",
          // },
          // {
          //   name: "数据字典",
          //   path: "/dataDictionary",
          //   icon: "key",
          // },
          // {
          //   name: "用户管理",
          //   path: "/userManage",
          //   icon: "key",
          // },
          // {
          //   name: "部门管理",
          //   path: "/departmentManage",
          //   icon: "key",
          // },
        ],
      },
    ],
    TabList: [],
    ActiveKey: "部门管理",
    current: 0,
    routerBase: "",
  },
  mutations: {
    //编辑tab切换页面功能
    [type.SetTabList](state, menus) {
      state.TabList = menus;
    },
    [type.SetActiveKey](state, key) {
      state.ActiveKey = key;
      // console.log(state.ActiveKey);
    },
    [type.SetCurrent](state, key) {
      state.current = key;
    },
    [type.SetRouterBase](state, key) {
      state.routerBase = key;
    },
  },
  actions: {
    [type.AsynSetTabList]({ commit }, payload) {
      commit("SetTabList", payload);
    },
  },
};

<template>
  <div>
    <a-modal
      title="工厂详情"
      :visible="config.visible"
      @cancel="handleCancel"
      width="854px"
    >
      <template slot="footer">
        <a-button @click="handleCancel"> 关闭 </a-button>
      </template>
      <div class="detail-box">
        <div class="left">
          <div class="left-item">
            <span>工厂名称:&nbsp; </span
            ><span class="content">{{ config.data.factoryName }}</span>
          </div>
          <div class="left-item">
            <span>工厂地址:&nbsp; </span
            ><span class="content">{{ config.data.factoryAddress }}</span>
          </div>
          <div class="left-item">
            <span>经营类型:&nbsp; </span
            ><span class="content">{{
              getMenuNameD(management, config.data.manageType)
            }}</span>
          </div>
          <div class="left-item">
            <span>经营范围:&nbsp; </span
            ><span class="content">{{ config.data.manageScope }}</span>
          </div>
          <div class="left-item">
            <span>工厂联系人:&nbsp; </span
            ><span class="content">{{ config.data.factoryLinkman }}</span>
          </div>
          <div class="left-item" style="display: flex">
            <span>营业执照:&nbsp; </span>
            <!-- <img class="imageLicense" :src="config.data.factoryLicense[0]" /> -->
            <viewer :image="config.data.factoryLicense">
              <img
                class="imageLicense"
                :key="1"
                :src="config.data.factoryLicense"
              />
            </viewer>
          </div>
          <div class="left-item">
            <span>验厂报告:&nbsp; </span
            ><span class="content">
              <a
                :href="config.data.inspectionReport"
                :download="config.data.inspectionReport"
                >{{ config.data.inspectionReport }}</a
              >
            </span>
          </div>
        </div>

        <div class="right-box">
          <div class="right-item">
            <span>统一社会信用社代码:&nbsp;</span
            ><span class="content">{{ config.data.factoryCreditCode }}</span>
          </div>
          <div class="right-item">
            <span>工厂性质:&nbsp;</span
            ><span class="content">{{
              getMenuNameD(factory, config.data.factoryType)
            }}</span>
          </div>
          <div class="right-item">
            <span>厂房房属权:&nbsp;</span
            ><span class="content">{{
              getMenuNameD(Ownership, config.data.escheatage)
            }}</span>
          </div>
          <div class="right-item">
            <span>厂房面积:&nbsp;</span
            ><span class="content">{{ config.data.plantAcreage }}</span>
          </div>
          <div class="right-item">
            <span>联系电话:&nbsp;</span
            ><span class="content">{{ config.data.factoryPhonenumber }}</span>
          </div>
          <div class="right-item" style="display: flex">
            <span>工厂门面照片:&nbsp; </span>
            <viewer :image="config.data.facadePic">
              <img class="imageLicense" :key="2" :src="config.data.facadePic" />
            </viewer>
          </div>
          <div class="right-item">
            <span>是否支持一件代发:&nbsp; </span>
            <span class="content">
              <a-radio-group :value="config.data.ifsend" disabled>
                <a-radio :value="true"> 是 </a-radio>
                <a-radio :value="false"> 否 </a-radio>
              </a-radio-group>
            </span>
          </div>
          <div class="right-item">
            <span>厂房面积:&nbsp;</span
            ><span class="content">{{ config.data.plantAcreage }}</span>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getMenuNameD } from "@/utils/utils.js";
export default {
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
          data: {}, //模态框数据
        };
      },
    },
  },
  data() {
    return {
      factory: [
        {
          value: 0,
          lable: "上市",
        },
        {
          value: 1,
          lable: "民营",
        },
        {
          value: 2,
          lable: "国企",
        },
        {
          value: 3,
          lable: "外资",
        },
        {
          value: 4,
          lable: "合资",
        },
        {
          value: 5,
          lable: "其他",
        },
      ],
      management: [
        {
          value: 0,
          lable: "制造",
        },
        {
          value: 1,
          lable: "贸易",
        },
        {
          value: 2,
          lable: "工贸一体",
        },
      ],
      Ownership: [
        {
          value: 0,
          lable: "租用",
        },
        {
          value: 1,
          lable: "自购",
        },
      ],
    };
  },
  methods: {
    getMenuNameD,
    handleCancel() {
      this.config.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.detail-box {
  display: flex;
  justify-content: space-around;
  .left {
    width: 50%;
  }
  .right-box {
    width: 50%;
  }
  .right-box-item,
  .left-item {
    min-height: 30px;
    line-height: 30px;
    display: flex;
    span:nth-child(1) {
      display: block;
      width: 100px;
      text-align: right;
      color: #999999;
    }
    .imageLicense {
      width: 110px;

      vertical-align: text-top;
      margin-bottom: 10px;
    }
  }
  .right-item {
    min-height: 30px;
    line-height: 30px;
    display: flex;
    // span:nth-child(2) {
    //   flex: 1;
    // }
    span:nth-child(1) {
      display: block;
      width: 137px;
      text-align: right;
      font-size: 14px;
      color: #999999;
    }
    .imageLicense {
      width: 110px;
      vertical-align: text-top;
      margin-bottom: 10px;
    }
    .title {
      width: 181px;
    }
  }
  .emplity {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .content {
    flex: 1;
    width: 15rem;
  }
}
</style>

<template>
  <div :class="{ clearfix: !showImage }">
    <a-upload
      action="http://192.168.233.83:10000/mall-base/mall/v1/oss/upload"
      list-type="picture-card"
      :file-list="[]"
      @preview="handlePreview"
      :customRequest="customRequest"
      :remove="removeFile"
      v-bind="$attrs"
      accept=".png,.jpg,.gif"
      :before-upload="beforeUpload"
    >
      <div>
        <a-icon type="plus" />
        <div class="ant-upload-text">上传图片</div>
      </div>
    </a-upload>
    <div style="position: relative; top: -12px">
      <slot name="tip"> </slot>
    </div>

    <draggable
      tag="ul"
      :list="datalist"
      v-bind="{ group: 'title', animation: 150 }"
      :no-transition-on-drag="true"
      :disabled="disableMove"
      @change="change"
    >
      <transition-group type="transition" name="spu" class="trans-group">
        <div class="spuImg" v-for="(item, index) in datalist" :key="index">
          <div class="overlay"></div>
          <img :src="item.url" alt="" />
          <a-icon
            class="icon-img icon-right"
            type="eye"
            @click="handlePreview(item)"
          />
          <a-icon
            v-if="!disableMove"
            class="icon-img icon-left"
            type="delete"
            @click="removeFile(item)"
          />
        </div>
      </transition-group>
    </draggable>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
import { UP_LOAD_PATH } from "@/variable";
import { initObsClient } from "@/utils/obs.js";
import draggable from "vuedraggable";
export default {
  name: "uplaod",
  props: {
    fileList: {
      content: [Array, String],
      default: () => {
        return [];
      },
    },
    // 为了解决之前代码使用的$attrs,所以禁用规则需要重新使用一个参数
    disableMove: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {
        return {
          maxLength: 20, //20张
          maxSize: 10, //20M
          width: 350, // 图片宽度
          height: 350, // 图片高度
        };
      },
    },
  },
  model: {
    prop: "fileList", //绑定的值，通过父组件传递
    event: "input", //自定义时间名
  },
  components: {
    draggable,
  },
  data() {
    return {
      previewVisible: false,
      previewImage: "",
      datalist: [],
      defaultData: {
        maxLength: 20, //20张
        maxSize: 10, //10M
        width: 350, // 图片宽度
        height: 350, // 图片高度
      },
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    async customRequest(data) {
      let res = await initObsClient(UP_LOAD_PATH, data.file);
      let obj = {
        uid: +new Date(),
        name: "image.png",
        status: "done",
        url: res.fileAddress,
      };
      let target = [...this.datalist, obj];
      // 批量上传图片，判断如果图片超过制定最大数量，阻止上传
      if (target?.length > this.defaultData.maxLength) {
        return;
      }
      this.datalist = target;
      // console.log(this.datalist);
      let inputFile;
      if (typeof this.fileList === "string") {
        inputFile = res.fileAddress;
      } else {
        inputFile = this.datalist.map((v) => v.url);
      }

      this.$emit("input", inputFile);
      this.$emit("blur", target);
    },
    removeFile(data) {
      this.datalist = this.datalist.filter((v) => v.url !== data.url);
      let target = this.datalist.map((v) => v.url);
      if (typeof this.fileList === "string") {
        target = "";
      }
      this.$emit("input", target);
      this.$emit("blur", target);
    },
    setUid(arr) {
      if (arr.length) return;
      arr.forEach((v) => {
        v.uid = +new Date();
      });
    },
    // 重新排序
    change() {
      if (this.disableMove) {
        return;
      } else {
        let imgArr = this.datalist.map((v) => v.url);
        this.$emit("draggable", imgArr);
        this.$emit("input", imgArr);
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.name.endsWith(".jpg") ||
        file.name.endsWith(".png") ||
        file.name.endsWith(".gif");
      if (!isJpgOrPng) {
        this.$message.error("格式只支持png 、jpg和gif");
      }
      const isLt2M = file.size / 1024 / 1024 < this.defaultData.maxSize;
      if (!isLt2M) {
        this.$message.error(`图片最大为${this.defaultData.maxSize}M`);
      }
      return isJpgOrPng && isLt2M && this.checkImageWH(file);
    },

    //返回一个 promise：检测通过则返回resolve；失败则返回reject，并阻止图片上传
    checkImageWH(file) {
      let self = this;
      return new Promise(function (resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = (e) => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function () {
            let valid =
              image.width >= self.defaultData.width &&
              image.height >= self.defaultData.height;
            console.log(valid);
            if (!valid) {
              self.$message.error(
                `图片最小尺寸为${self.defaultData.width}X${self.defaultData.height}`
              );
            }
            valid ? resolve() : reject();
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },
  },
  computed: {
    showImage() {
      let target = true;
      if (typeof this.fileList === "string") {
        target = this.fileList.length < 1;
      } else {
        target = this.fileList.length < this.defaultData.maxLength;
      }
      return target;
    },
  },
  created() {
    // console.log(this.disableMove);
    //设置默认值
    Object.assign(this.defaultData, this.config);
    if (this.fileList) {
      if (typeof this.fileList === "string") {
        let target = this.fileList
          ? [
              {
                url: this.fileList,
                uid: +new Date() + Math.random() * 1000,
                name: +new Date() + Math.random() * 1000 + ".png",
                status: "done",
              },
            ]
          : [];
        this.setUid(target);
        this.datalist = target;
      } else {
        setTimeout(() => {
          let target = Array.from(this.fileList);
          target.forEach((item) => {
            let obj = {
              url: item,
              uid: +new Date() + Math.random() * 1000,
              name: +new Date() + Math.random() * 1000 + ".png",
              status: "done",
            };
            this.datalist.push(obj);
          });
        }, 100);
      }
    }
  },
};
</script>
<style scoped lang="scss">
.spuImg {
  width: 102px;
  height: 102px;
  border: 1px solid #ccc;
  position: relative;
  margin-right: 10px;

  img {
    width: 85%;
    height: 85%;
    position: absolute;
    transform: translate(9%, 9%);
    object-fit: cover;
  }
  .overlay {
    width: 85%;
    height: 85%;
    position: absolute;
    transform: translate(9%, 9%);
    background: rgba(0, 0, 0, 0.3);
    display: none;
  }

  .icon-img {
    z-index: 100;
    display: none;
    position: absolute;
    font-size: 16px;
  }
  .icon-left {
    top: 45%;
    left: 60%;
  }
  .icon-right {
    top: 45%;
    left: 30%;
  }
}

.spuImg:hover {
  .overlay {
    display: block;
    z-index: 100;
  }
  .icon-img {
    display: block;
    cursor: pointer;
    color: #ccc;
  }
}

.trans-group {
  display: flex;
  flex-wrap: wrap;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-picture-card-wrapper {
  display: table;
  flex-wrap: wrap;
  white-space: nowrap;
  /deep/.ant-upload-list-picture-card {
    // display: inline-flex !important;
    flex-wrap: wrap;
  }
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/.ant-upload-select-picture-card {
  // display: table-cell;
}
.clearfix {
  /deep/.ant-upload-select-picture-card {
    display: none;
  }
}
</style>

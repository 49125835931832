<template>
  <div>
    <div class="title">
      <a-form-model
        :model="form"
        :rules="rules"
        ref="form"
        style="display: flex; flex-wrap: wrap"
      >
        <div style="width: 100%">
          <span style="color: #ff4600">▋</span> <span>基础信息 </span>
        </div>
        <a-form-model-item
          class="alignment oneline"
          label="商品名称(中文)："
          prop="goodsNameCn"
        >
          <a-input
            placeholder="请输入"
            :disabled="inputdisabled"
            v-model="form.goodsNameCn"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment oneline"
          label="商品名称(英文)："
          prop="goodsNameEn"
        >
          <numberInput
            placeholder="请输入"
            :disabled="inputdisabled"
            v-model="form.goodsNameEn"
            :config="englishConfig"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline factory"
          label="工厂名称："
          prop="tenantId"
        >
          <SelectPullpRefresh
            :disabled="inputdisabled"
            :config="FactoryConfig"
            v-model="form.tenantId"
            :targetName="targetName"
            @filter="changefactory"
            placeholder="请选择"
            :getPopupContainer="handleGetContain"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline"
          label="分类："
          prop="goodsCategoryId"
        >
          <a-cascader
            :options="treeData"
            placeholder="请选择"
            @change="onChange"
            :disabled="inputdisabled"
            :value="form.goodsCategoryId"
            class="lj-xp-casder"
            :getPopupContainer="handleGetContainC"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline"
          label="品牌："
          prop="brandName"
        >
          <a-input
            placeholder="请输入"
            :disabled="inputdisabled"
            v-model="form.brandName"
          />
        </a-form-model-item>
        <a-form-model-item class="alignment twoline" label="特殊标签">
          <tags-input
            :TgOptions="{ title: '添加标签名' }"
            v-model="form.specialTag"
            :disabled="inputdisabled"
          >
          </tags-input>
        </a-form-model-item>
        <a-form-model-item class="alignment twoline" label="自定义标签: ">
          <a-checkbox
            :disabled="inputdisabled || customTagsStatus"
            v-model="form.customTags"
          >
            私域礼品
          </a-checkbox>
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline"
          label="支持业务: "
          prop="customcheck"
        >
          <a-checkbox-group
            v-model="form.customcheck"
            :options="plainOptions"
            @change="checkChange"
            :disabled="inputdisabled"
          >
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline company"
          label="质保期: "
          prop="warranty"
        >
          <company-input
            :config="warrantyCompany"
            v-model="form.warranty"
            :disabled="inputdisabled"
            :CompanyNum.sync="form.warrantyCompany"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline company"
          label="生产周期: "
          prop="productionCycle"
        >
          <company-input
            v-model="form.productionCycle"
            :disabled="inputdisabled"
            :config="productionCompany"
            :CompanyNum.sync="form.productionCompany"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment oneline"
          label="包装清单: "
          prop="packingList"
        >
          <a-input
            placeholder="请输入"
            :disabled="inputdisabled"
            v-model="form.packingList"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment oneline"
          label="商品卖点: "
          prop="commoditySellingPoint"
        >
          <a-textarea
            style="width: 100%"
            :auto-size="{ minRows: 6 }"
            :disabled="inputdisabled"
            :maxLength="256"
            v-model="form.commoditySellingPoint"
          ></a-textarea>
          <span class="lenNum">已输入{{ length }}/256</span>
        </a-form-model-item>

        <!-- SPU部分 -->
        <a-form-model-item
          class="alignment oneline imagepos"
          label="SPU图片: "
          prop="spuImags"
        >
          <p class="tips">支持.jpg .png 格式，最多不超过20张</p>
          <uploadImageDrop
            :disabled="inputdisabled"
            :disableMove="inputdisabled"
            :multiple="true"
            @blur="handleBlur('spuImags')"
            v-model="form.spuImags"
          />
        </a-form-model-item>
        <a-form-model-item class="alignment oneline imagepos" label="SPU视频: ">
          <p class="tips">
            仅支持.mp4 格式，最大不超过10M，最优播放比例1:1，请点击预览
          </p>
          <uploadVideo :disabled="inputdisabled" v-model="form.spuVideos" />
        </a-form-model-item>

        <!-- 商品详情部分 -->
        <a-form-model-item
          class="alignment oneline imagepos"
          label="商品详情图: "
          prop="detailImages"
        >
          <p class="tips">支持.jpg .png 格式，最多不超过20张</p>
          <uploadImageDrop
            :disabled="inputdisabled"
            :disableMove="inputdisabled"
            :multiple="true"
            :config="{ width: 450, height: 450 }"
            @blur="handleBlur('detailImages')"
            v-model="form.detailImages"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment oneline imagepos"
          label="商品详情视频: "
        >
          <p class="tips">
            支持.mp4 格式，最大不超过10M，最优播放比例1:1，请点击预览
          </p>
          <uploadVideo :disabled="inputdisabled" v-model="form.detailVideos" />
        </a-form-model-item>

        <div style="width: 100%">
          <span style="color: #ff4600">▋</span> <span>基础信息 </span>
        </div>
        <a-form-model-item
          class="alignment twoline company"
          label="定制起订量: "
          prop="minOrder"
        >
          <company-input
            v-model="form.minOrder"
            :config="minOrderUtils"
            :disabled="inputdisabled"
            :CompanyNum.sync="form.minOrderUtils"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline company"
          label="库存起批量: "
          prop="minInventory"
        >
          <company-input
            v-model="form.minInventory"
            :config="inventoryConfig"
            :disabled="inputdisabled"
            :isDisabled="inventorystatus"
            :CompanyNum.sync="form.inventoryConfig"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment oneline"
          label="现货所在地: "
          prop="address"
        >
          <a-input
            placeholder="请输入"
            :disabled="inputdisabled"
            v-model="form.address"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline"
          label="库存处理标签"
          prop="inventoryTags"
        >
          <tags-input
            :TgOptions="{ title: '添加标签名' }"
            v-model="form.inventoryTags"
            :disabled="inputdisabled"
          >
          </tags-input>
        </a-form-model-item>
        <a-form-model-item
          class="alignment oneline"
          label="现货规格: "
          prop="spotSpecification"
        >
          <a-select v-model="form.spotSpecification" :disabled="inputdisabled" placeholder="请输入">
            <a-select-option
              v-for="(item, index) in spotConfig"
              :key="index"
              :value="item.label"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          class="alignment oneline imagepos"
          label="可售区域: "
          prop="availableArea"
        >
          <a-checkbox-group
            v-model="form.availableArea"
            :disabled="inputdisabled"
            class="alignmentArea"
            @change="handleBlur('availableArea')"
          >
            <a-checkbox
              class="alignmentArea-item"
              v-for="(item, index) in salesArea"
              :key="index"
              :value="item.dictValue"
              >{{ item.dictValue }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-model-item>
        <div style="width: 100%">
          <span style="color: #ff4600">▋</span> <span>包装信息 </span>
        </div>
        <a-form-model-item
          class="alignment twoline"
          label="商品毛重(kg/件): "
          prop="grossWeight"
          style="margin-right: 30px"
        >
          <numberInput
            placeholder="请输入"
            :disabled="inputdisabled"
            v-model="form.grossWeight"
            :config="numberConfig"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline"
          label="商品净重(kg/件): "
          prop="netWeight"
        >
          <numberInput
            placeholder="请输入"
            :disabled="inputdisabled"
            v-model="form.netWeight"
            :config="numberConfig"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline"
          label="包装尺寸cm(长*宽*高)"
          prop="packingSize"
          style="margin-right: 30px"
        >
          <a-input
            placeholder="请输入"
            :disabled="inputdisabled"
            v-model="form.packingSize"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline"
          label="外包装尺寸cm(长*宽*高)："
          prop="outerPackingSize"
        >
          <a-input
            placeholder="请输入"
            :disabled="inputdisabled"
            v-model="form.outerPackingSize"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline"
          label="商品尺寸cm(长*宽*高)： "
          prop="goodsSize"
          style="margin-right: 30px"
        >
          <a-input
            placeholder="请输入"
            :disabled="inputdisabled"
            v-model="form.goodsSize"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment twoline"
          label="箱规(件)"
          prop="casesGauge"
        >
          <numberInput
            placeholder="请输入"
            v-model="form.casesGauge"
            :disabled="inputdisabled"
          />
        </a-form-model-item>
        <a-form-model-item
          class="alignment oneline imagepos"
          label="包装图片 : "
          prop="packagingImags"
        >
          <uploadImage
            v-model="form.packagingImags"
            :disabled="inputdisabled"
            :config="picturesConfig"
            @blur="handleBlur('packagingImags')"
          />
          <p class="tips">支持.jpg .png 格式，最多不超过4张</p>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js"
import CompanyInput from "./component/CompanyInput.vue"
import uploadImage from "@/components/uploadImage"
import uploadImageDrop from "@/components/uploadImageDrop"
import uploadVideo from "@/components/uploadVideo"
import TagsInput from "./component/inputTag.vue"
import { SelectionGoods } from "@/api/SelectionGoods.js"
import SelectPullpRefresh from "@/components/SelectPullpRefresh/SelectPullpRefresh.vue"
import numberInput from "@/components/numberInput"
export default {
  name: "goodDetailModel",
  props: {
    editData: {
      type: Object,
    },
  },
  data () {
    return {
      form: {
        customized: true,
        goodsNameCn: null, // 选品中文名称
        goodsNameEn: null, //选品英文名称
        tenantId: undefined, //工厂id
        factoryName: "", //工厂名称
        goodsCategoryId: undefined, //选品分类
        goodsCategoryName: null, //商品名称
        brandName: "定制品牌", // 品牌
        specialTag: null, //特殊标签
        customTags: true, //自定义标签
        warranty: 1, //质保期
        warrantyCompany: null, //danwei1
        productionCycle: 1, //生产周期
        productionCompany: null, //生产周期单位
        packingList: null, // 包装清单
        commoditySellingPoint: null, //商品买点
        spuImags: [], // sup图片
        spuVideos: [], // spu视频
        detailImages: [], // 商品详情图片
        detailVideos: [], // 商品详情视频
        minOrder: 1, //定制起订量
        minOrderUtils: null, //定制起订量单位
        minInventory: 1,
        inventoryConfig: null, //库存起批量单位
        address: null, // 现货所在地
        availableArea: [], //可售区域
        grossWeight: null, //商品毛重
        netWeight: null, //商品净重
        packingSize: null, //包装尺寸
        outerPackingSize: null, //外包装尺寸
        goodsSize: null, // 商品尺寸
        casesGauge: null, //箱规
        packagingImags: [], //包装图片
        customcheck: ['customized'],
      },
      rules,
      salesArea: [],
      targetName: [],
      FactoryConfig: {
        url: "mall/factory/dropdownFactory",
        valid: "factoryId",
        vallable: "factoryName",
        path: ["data", "records"], //数据在data的位置
      },

      CompanyNum: "年",
      treeData: [], //可售区域
      Factory: [], //工厂列表
      minOrderUtils: [], //定制起订量单位
      warrantyCompany: [], //质保期
      productionCompany: [], //生产周期
      picturesConfig: {
        maxLength: 4,
        maxSize: 10,
      },
      numberConfig: {
        reg: /^(([1-9]\d*)(\.\d+)?)$|^0\.\d*[1-9]$/,
        msg: "请输入不包含零的正数",
      },
      englishConfig: {
        reg: /^([a-zA-Z-\s])+$/,
        msg: "请输入英文字母",
        type: "String",
      },
      customTagsStatus: null,
      inventoryConfig: [],
      spotConfig: [
        { value: 1, label: "国标" },
        { value: 2, label: "欧规" },
        { value: 3, label: "美规" },
        { value: 4, label: "英规" },
        { value: 5, label: "澳规" },
        { value: 6, label: "日规" },
        { value: 7, label: "韩规" },
        { value: 8, label: "南非标" },
        { value: 9, label: "其他" },
      ],
      plainOptions: [
        {
          value: "customized",
          label: "定制",
        },
        { value: "inventory", label: "库存处理" },
      ],
      inventorystatus: false
    }
  },
  watch: {
    form: {
      handler (newValue) {
        this.$emit("getConfig", newValue)
        if (newValue.inventory && !newValue.customized) {
          this.form.customTags = false
          this.customTagsStatus = true
        } else {
          this.customTagsStatus = false
        }
        if (newValue.inventory && newValue.customized) {
          this.form.inventoryConfig = this.form.minOrderUtils
          this.inventorystatus = true
        } else {
          this.inventorystatus = false
        }
      },
      deep: true,
    },
  },
  components: {
    CompanyInput,
    uploadImage,
    uploadImageDrop,
    SelectPullpRefresh,
    numberInput,
    TagsInput,
    uploadVideo,
  },
  methods: {
    validateForm (type) {
      if (type === undefined) {
        return new Promise((resovle) => {
          let validateConfig = []
          this.$refs.form.clearValidate()
          if (this.form.inventory && !this.form.customized) {
            validateConfig = ['goodsNameCn', 'goodsNameEn', 'tenantId', 'goodsCategoryId', 'brandName',
              'customcheck', 'warranty', 'packingList', 'commoditySellingPoint', 'spuImags',
              'detailImages', 'minInventory', 'address', 'inventoryTags', 'spotSpecification',
              'availableArea', 'grossWeight', 'netWeight', 'packingSize', 'outerPackingSize', 'goodsSize',
              'casesGauge', 'packagingImags']
          } else if (this.form.customized && !this.form.inventory) {
            validateConfig = ['goodsNameCn', 'goodsNameEn', 'tenantId', 'goodsCategoryId', 'brandName',
              'customcheck', 'warranty', 'packingList', 'commoditySellingPoint', 'spuImags',
              'detailImages', 'availableArea', 'grossWeight', 'netWeight', 'packingSize', 'outerPackingSize', 'goodsSize',
              'casesGauge', 'packagingImags']
          } else {
            validateConfig = ['goodsNameCn', 'goodsNameEn', 'tenantId', 'goodsCategoryId', 'brandName',
              'customcheck', 'warranty', 'packingList', 'commoditySellingPoint', 'spuImags',
              'detailImages', 'minInventory', 'address', 'inventoryTags', 'spotSpecification',
              'availableArea', 'grossWeight', 'netWeight', 'packingSize', 'outerPackingSize', 'goodsSize',
              'casesGauge', 'packagingImags']
          }

          let checkStatusList =[]//校验提示信息列表
            validateConfig.forEach(v=>{
                //遍历每一项，触发每一项的校验，同时收集提示信息
                this.$refs.form.validateField(v, (errorMessage) => {
                if(errorMessage){
                    checkStatusList.push(errorMessage)
                }
            })
            })
            //判断是否提示信息列表长度，等于0说明校验通过
            if(checkStatusList.length===0){
                let data = this.concatData()
               resovle({ isok: true, data })
            }else{
                let data = this.concatData()
               resovle({ isok: false, data })
            }
        })

      } else if (type === "noValidate") {
        console.log('noValidate',type);
        return new Promise((resovle) => {
          let data = this.concatData()
          resovle({ isok: true, data })
        })
      }
    },
    checkChange (checkedValues) {
      if (checkedValues[0] === "customized") {
        this.form.customized = true
        this.form.inventory = checkedValues[1] ? true : false
      } else if (checkedValues[0] === "inventory") {
        this.form.inventory = true
        this.form.customized = false
      } else {
        this.form.customized = false
        this.form.inventory = false
      }
    },
    // 拼接数据
    concatData () {
      let data = {
        ...this.form,
        warranty: this.form.warranty + "," + this.form.warrantyCompany,
        productionCycle:
          this.form.productionCycle + "," + this.form.productionCompany,
        minOrder: this.form.minOrder + "," + this.form.minOrderUtils,
        minInventory: this.form.minInventory && this.form.inventoryConfig ? this.form.minInventory + "," + this.form.inventoryConfig : null,
        availableArea: this.form.availableArea.join(","),
        goodsCategoryId:
          this.form.goodsCategoryId === undefined
            ? undefined
            : this.form.goodsCategoryId[this.form.goodsCategoryId.length - 1],
      }
      return data
    },

    async getCateDate () {
      let res = await SelectionGoods.getCateGoryData()
      if (res.success) {
        this.addParams(res.data)
        this.treeData = res.data
        // 如果是在编辑状态数据回填
        if (this.editData?.data) {
          // 分装一个方法根据ID找对象
          let target1 = this.editData.data
          let arr = []
          let target = this.getTargetObj(
            this.treeData,
            target1.goodsCategoryId
          )
          if (target) {
            arr.unshift(target.id)
            let getArrData = function (obj) {
              // 如果parentId == null 停止向上找
              if (obj.parentId == null || obj.parentId == 0) {
                return
              } else {
                let target = this.getTargetObj(this.treeData, obj.parentId)
                arr.unshift(target.id)
                getArrData(target)
              }
            }
            getArrData = getArrData.bind(this)
            getArrData(target)
          }
          this.form.goodsCategoryId = arr
        }
      }
    },
    getTargetObj (arr, id) {
      let target
      let getTarget = function (arr, id) {
        for (const item of arr) {
          if (target) break //如果有值就不遍历了
          if (item.id === id) {
            target = item
            break
          } else if (item.children) {
            getTarget(item.children, id)
          }
        }
      }
      getTarget(arr, id)
      return target
    },
    addParams (arr) {
      arr.forEach((v) => {
        v.key = v.id
        v.title = v.label
        v.value = v.id
        if (v.children && v.children.length) {
          this.addParams(v.children)
        }
      })
    },
    async getlistDetailByName (seartext, dataKey) {
      let res = await SelectionGoods.getlistDetailByName(seartext)
      if (res.success) {
        this[dataKey] = res.data
        // 如果不是可售区域都有默认值并且是编辑状态
        if (seartext !== "可售区域" && !this.editData?.data) {
          let target = res.data[0].dictValue
          this.form[dataKey] = target
          if (seartext === "质保期") {
            this.form[dataKey] = "月"
          }
          if (seartext === "生产周期") {
            this.form[dataKey] = "日"
          }
        }
      }
    },
    async getdropdownFactory () {
      let res = await SelectionGoods.getDropdownFactory()
      if (res.success) {
        this.Factory = res.data.records
      }
    },
    changefactory (data, Factory) {
      let target = Factory.find((v) => v.factoryId === data)
      this.form.factoryName = target.factoryName
    },
    onChange (data, target) {
      if (target) {
        this.form.goodsCategoryId = data
        this.form.goodsCategoryName = target[data.length - 1].title
      } else {
        this.form.goodsCategoryId = ""
        this.form.goodsCategoryName = ""
      }
    },
    handleBlur (data) {
      this.$refs.form.validateField(data)
    },
    handleGetContain (data) {
      return data
    },
    handleGetContainC () {
      return document.querySelector(".lj-xp-casder").parentNode
    },
  },
  created () {
    if (this.editData?.data) {
      let target = JSON.parse(JSON.stringify(this.editData.data))
      target.availableArea =
        target.availableArea === "" ? [] : target.availableArea.split(",")

      let arr1 = target.warranty?.split(",")
      console.log(arr1);
      target.warrantyCompany =arr1? arr1[1]:null
      target.warranty = arr1? arr1[0]:null

      let arr2 = target.productionCycle?.split(",")
      target.productionCompany = arr2? arr2[1]:null
      target.productionCycle = arr2? arr2[0]:null

      let arr3 = target.minOrder?.split(",")
      target.minOrderUtils = arr3? arr3[1]:null
      target.minOrder = arr3? arr3[0]:null

      let arr4 = target.minInventory?.split(",")
      target.inventoryConfig = arr4 ? arr4[1] : ""
      target.minInventory = arr4 ? arr4[0] : ""

      let arr5 = []
      if (target.customized && target.inventory) {
        arr5[0] = "customized"
        arr5[1] = "inventory"
      } else if (target.customized && !target.inventory) {
        arr5[0] = "customized"
      } else if (target.inventory && !target.customized) {
        arr5[0] = "inventory"
      }
      target.customcheck = arr5
      let arr = [
        { factoryId: target.tenantId, factoryName: target.factoryName },
      ]
      // this.$set(this.FactoryConfig,'targetName',arr)
      this.targetName = arr
      this.form = target
      // 先不要给casderid
      this.form.goodsCategoryId = undefined
      // this.form.customcheck = "11";
    }
    this.getCateDate()
    this.getlistDetailByName("可售区域", "salesArea")
    this.getlistDetailByName("定制起订量单位", "minOrderUtils")
    this.getlistDetailByName("库存处理起批量单位", "inventoryConfig")
    this.getlistDetailByName("生产周期", "productionCompany")
    this.getlistDetailByName("质保期", "warrantyCompany")
    // this.getdropdownFactory();
  },
  computed: {
    inputdisabled () {
      return this.editData.type === "uninput"
    },
    length () {
      if (this.form.commoditySellingPoint === "") {
        return 0
      }
      return (
        this.form.commoditySellingPoint &&
        this.form.commoditySellingPoint.length
      )
    },
  },
};
</script>

<style scoed lang="scss">
.alignment {
  display: flex !important;
  .ant-form-item-label {
    width: 132px;
    label {
      white-space: initial;
      display: block;
      height: 100%;
      line-height: unset;
    }
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
}
.oneline {
  width: 100%;
}
.twoline {
  width: 45%;
}
.factory {
  .ant-form-item-control {
    width: 25rem;
  }
}
.company .ant-form-item-control {
  position: relative;
  top: 5px;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.alignmentArea {
  // display: flex;
  display: inline-flex;
  flex-wrap: wrap;
  .alignmentArea-item {
    width: 23%;
    margin-left: 8px;
  }
}
.imagepos {
  label {
    top: -11px;
  }
}
.tips {
  height: 20px;
  font-size: 14px;
  color: rgba(51, 51, 51, 0.65);
  line-height: 10px;
}
.lenNum {
  position: absolute;
  right: 1rem;
  bottom: -1rem;
}
/deep/ .ant-cascader-menus {
  left: 0px !important;
}
/deep/ .ant-select-selection-selected-value {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

<template>
  <a-modal
    :visible="config.visible"
    @cancel="close"
    @ok="handleOk"
    :footer="null"
    class="ant-modal-delete"
  >
    <div class="box" v-if="config.visible">
      <div class="icon">
        <a-icon type="exclamation-circle" theme="filled" />
      </div>
      <div class="title">{{ config.title }}</div>
      <div class="footer">
        <slot>
          <a-button @click="close"> 取消 </a-button>
          <a-button @click="handleOk" type="primary" :loading="loading">
            确认
          </a-button>
        </slot>
      </div>
    </div>
  </a-modal>
</template>

<script>
import axios from "@/config/http.js";
export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          visible: true,
          title: "确定要下架厨卫器吗?", //标题
          url: "", //请求地址
          method: "", //请求方式
          message: "",
          data: {}, //传参
        };
      },
    },
    isLoading: {
      type: Boolean,
      default: true, // 有加载效果
    },
  },
  data() {
    return {
      loading: false,
      title: "",
    };
  },
  created() {
    if (!this.config.title) this.title = "确定要删除此数据吗";
  },
  methods: {
    close() {
      this.config.visible = false;
    },
    handleOk() {
      this.$emit("ok", this.config.data);
      //如果传入url则发请求
      if (this.config.url) {
        this.handleOkDelete();
      }
    },
    handleOkDelete() {
      let reqMethod = this.config.method ? this.config.method : "delete"; //默认是删除方法
      if (this.isLoading) this.loading = true;
      axios[reqMethod](this.config.url, this.config.data)
        .then((res) => {
          if (res.success) {
            this.$emit("compile", res);
            let msg = this.config.message //没有传入消息弹出默认消息
              ? this.config.message
              : reqMethod === "delete"
              ? "删除成功"
              : "修改成功";
            this.$message.success(msg);
          } else {
            this.$message.error(res.errorMsg);
          }
          this.close();
          this.loading = false;
        })
        .catch(() => {
          this.close();
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang='scss'>
.box {
  display: inline-flex;
  position: relative;
  height: 100%;
  width: 100%;
  .title {
    margin-left: 16px;
    line-height: 37.5px;
    font-size: 16px;
    font-family: "Source Han Sans CN";
  }
  .icon {
    font-size: 25px;
    color: #faad14;
  }
}
.footer {
  text-align: center;
  // margin-top: 50px;
  width: 180px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  button {
    margin: 0 5px;
  }
}
/deep/.ant-modal-content {
  width: 498px;
  height: 170px;
  border-radius: 2px;
}
.ant-modal-delete {
  top: 50% !important;
}
/deep/.ant-modal-body {
  height: 100%;
}
</style>
import store from '@/store'
//添加tabs路由页面
export function changeTab(to){
  // console.log(to)
//拉取当前公共路由信息及路由base
  let tabList=[...store.state.Menu.TabList]
  let base=store.state.Menu.routerBase
  let haves=false

  //判断当前路由是否存在观察池中，存在则更新当前tabs key
  tabList.map((i)=>{
    if(i.title === to.meta.title){
      haves=true
    }
  })
  //不存在则新增
  if(!haves){
    // to   有title则添加
    if(to.meta?.title)tabList.push({'title':to.meta.title,'path':to.fullPath,'name':base})
    store.dispatch('Menu/AsynSetTabList',tabList)
    haves=false
  }
}
<template>
  <div>
    <input @change="changeFile" type="file" ref="file" style="display: none" />
    <div>
      <a-button @click="handleFile" class="button">
        <a-icon type="upload" /> 上传附件
      </a-button>
      <span>
        <slot name="tip"> </slot>
      </span>
    </div>
    <div v-for="(item, index) in fileName" :key="item" class="file-icon">
      <div
        :title="item"
        class="fileName"
        style="height: 22px; line-height: 26px"
      >
        {{ item }}
      </div>
      <div class="icon">
        <a-icon
          type="delete"
          @click="deleteData(index)"
          style="margin: 5px; display: block"
        />
      </div>
    </div>
    <a-progress v-show="progress" :percent="progress" size="small" />
  </div>
</template>
<script>
import { UP_LOAD_PATH } from "@/variable";
// import axios from "axios";
import { initObsClient } from "@/utils/obs.js";
export default {
  name: "uploadFile",
  data() {
    return {
      UP_LOAD_PATH,
      fileName: [],
      progress: 0,
      defaultData: {
        maxLength: 5, //文件上传最多五个
        maxSize: 10, //文件上传最大10M
      },
    };
  },
  props: {
    value: {
      content: [Array, String],
      default() {
        return [];
      },
    },
    config: {
      type: Object,
      default() {
        return {
          maxLength: Array.isArray(this.value) ? 5 : 1, //如果是数组那么maxlength就是5,如果是字符串maxlangth就是1
          maxSize: 10, //10M
          fileType: [".exe"],
        };
      },
    },
  },
  methods: {
    async changeFile(data) {
      data = data.target.files[0];
      // const isJpgOrPng = data.name.endsWith(".exe");
      // if (isJpgOrPng) {
      //   this.$message.error("不支持.exe文件格式");
      //   return;
      // } else {
      //   if (this.defaultData.fileType) {
      //     const isJpgOrPng =
      //       data.name.endsWith(".excel") ||
      //       data.name.endsWith(".ppt") ||
      //       data.name.endsWith(".pdf");
      //     if (!isJpgOrPng) {
      //       this.$message.error("只支持.excel、.pdf、.ppt格式文件");
      //       return;
      //     }
      //   }
      // }
      if (this.defaultData.fileType.length) {
        const isJpgOrPng =
          data.name.endsWith(this.defaultData.fileType[0]) ||
          data.name.endsWith(this.defaultData.fileType[1]) ||
          data.name.endsWith(this.defaultData.fileType[2]) ||
          data.name.endsWith(this.defaultData.fileType[3])
        if (!isJpgOrPng) {
          this.$message.error("只支持.excel、.pdf、.ppt格式文件");
          return;
        }
      } else {
        const isJpgOrPng = data.name.endsWith(".exe");
        if (isJpgOrPng) {
          this.$message.error("不支持.exe文件格式");
          return;
        }
      }

      if (data.size / 1024 / 1024 > this.defaultData.maxSize) {
        return this.$message.error("上传文件过大");
      }
      let res = await initObsClient(UP_LOAD_PATH, data);
      if (res === false) {
        return this.$message.error("上传失败");
      }
      if (this.defaultData.maxLength == 1) {
        this.fileName = [res.fileAddress];
        this.$emit("input", res.fileAddress);
      } else {
        this.fileName.push(res.fileAddress);
        this.$emit("input", [...this.value, res.fileAddress]);
      }
      this.$emit("blur");
      // }
    },
    handleFile() {
      if (this.fileName.length === this.defaultData.maxLength) {
        return;
      }
      this.$refs.file.click();
    },
    deleteData(data) {
      if (this.defaultData.maxLength == 1) {
        this.fileName = [];
        this.$emit("input", "");
        this.$emit("blur");
      } else {
        let target = (this.fileName = this.fileName.filter((v, index) => {
          return index != data;
        }));
        this.$emit("input", target);
        this.$emit("blur");
      }
    },
    handleProgress(e) {
      this.progress = Math.ceil((e.loaded / e.total) * 100);
      if (this.progress >= 100) {
        setTimeout(() => {
          this.progress = 0;
        }, 200);
      }
    },
  },
  // mounted() {
  //   if (this.value && this.value.length) {
  //     this.fileName = this.value;
  //   }
  // },
  created() {
    Object.assign(this.defaultData, this.config);
  },
  watch: {
    value: {
      handler() {
        if (this.value && this.value.length && !this.fileName?.length) {
          if (Array.isArray(this.value)) {
            this.fileName = this.value;
          } else {
            this.fileName = [this.value];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss">
.fileName {
  width: 194px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-icon {
  display: flex;
  .icon {
    line-height: 22px;
  }
}
.tip {
  color: rgba(51, 51, 51, 0.65);
  margin-top: -5px;
  font-size: 14px;
}
.button {
  display: block;
}
</style>

<template>
  <a-cascader
    :options="treeData"
    placeholder="请选择"
    @change="onChange"
    :allowClear='false'
    :value="myValue"
    style="width: 100%"
  />
</template>

<script>
export default {
  name: "selectTree",
  props: {
    treeData: {
    },
    value: {
      default: undefined,
    },
  },
  data() {
    return {
      myValue: [],
    };
  },
  methods: {
    onChange(data) {
      this.myValue = data;
      this.$emit("input", data[data.length - 1]);
      this.$emit("blur");
    },
    handleBlur() {
      this.$emit("blur");
    },
    reset() {
      this.myValue = [];
      this.$emit("input", undefined);
    },
  },
  watch: {},
};
</script>
<style scoped lang='scss'>
/deep/.ant-cascader-picker-label {
  top: 43%;
  // left: -58px;
  text-align: left;
}
</style>



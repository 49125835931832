//获取全部数据
export function generateList(data) {
  data.map((i) => {
    i.key = +i.id;
    i.value = i.name;
    i.title = i.name;
    i.scopedSlots = { title: "custom" };
    if (i.children && i.children.length > 0) {
      generateList(i.children);
    }
  });
  return data;
}

export function creatKey(data)  {
  data.map((i) => {
    i.key = i.id || i.resourceId;
  });
  return data;
}

export function filterOpt(input, option) {
  return (
    option.componentOptions.children[0].text
      .toLowerCase()
      .indexOf(input.toLowerCase().trim()) >= 0
  );
}

export const Creatrequest = {
  // 设置按钮大小
  SetButtonsize(data) {
    if (data >= 1300) {
      console.log("da");
    } else {
      console.log("xiao");
    }
  },
};

// 给表格设置key属性
export function setKey(data, key = "id") {
  return data.map((v) => {
    v.key = v[key];
    return v;
  });
}
// 数组去重  用法 let targetArr = uniqueFunc(arr,ids) targetArr就是经过ids去重的函数
export function uniqueFunc(arr, uniId = "id") {
  let hash = {};
  return arr.reduce((accum, item) => {
    hash[item[uniId]] ? "" : (hash[item[uniId]] = true && accum.push(item));
    return accum;
  }, []);
}
// 深拷贝

function isObject(value) {
  const valueType = typeof value;
  return (
    (valueType !== null && valueType === "object") || valueType === "function"
  );
}
export function deepClone(orgobj, map = new WeakMap()) {
  if (!isObject(orgobj)) return orgobj;
  // 处理函数
  if (orgobj instanceof Set) {
    return new Set([...orgobj]);
  }
  if (orgobj instanceof Map) {
    return new Map([...orgobj]);
  }
  if (typeof orgobj === "function") return orgobj;
  if (typeof orgobj === "symbol") return Symbol(orgobj.description);
  if (map.has(orgobj)) {
    return map.get(orgobj);
  }
  const newObj = Array.isArray(orgobj) ? [] : {};
  map.set(orgobj, newObj);
  for (const key in orgobj) {
    newObj[key] = deepClone(orgobj[key], map);
  }
  const symbolKeys = Object.getOwnPropertySymbols(orgobj);
  for (const skey of symbolKeys) {
    newObj[skey] = deepClone(orgobj[skey]);
  }
  return newObj;
}

// 以value值拿取枚举值（name）
export function getMenuName(arr = [], value) {
  for (let item of arr) {
    if (item.value === value) {
      return item.name;
    }
  }
}
export function getMenuNameD(arr = [], value) {
  for (let item of arr) {
    if (item.value === value) {
      console.log(item.lable);
      return item.lable;
    }
  }
}

// 计算树形叶子结点的值（自上而下进行数量累计计算）
export function updateTreeDataAdd(treeData, fieldName) {
  let addSum = 0;
  for (let i = 0; i < treeData.length; i++) {
    //只有存在子集时，才进行累加计算，否则不修改自身属性值
    if (treeData[i].children) {
      //递归遍历并修改children的对应值
      treeData[i].children = updateTreeDataAdd(treeData[i].children, fieldName);
      let addSum = 0;
      //循环相加计算和
      for (let j = 0; j < treeData[i].children.length; j++) {
        let childNum = treeData[i].children[j][fieldName];
        childNum = Number(childNum);
        addSum += childNum;
      }
      //赋值
      if (addSum !== 0) {
        treeData[i][fieldName] = addSum.toFixed(1);
      }
    } else if (!treeData[i][fieldName]) {
      if (addSum !== 0) {
        treeData[i][fieldName] = addSum.toFixed(1);
      }
    }
  }

  return treeData;
}

// 单独计算顶层总分
export function topTreeNode(treeData, fieldName) {
  console.log("计算顶层分数", treeData);
  let sum = 0;
  for (let i = 1; i < treeData.length; i++) {
    let res = treeData[i][fieldName];
    res = Number(res);
    sum += res;
  }
  treeData[0][fieldName] = sum;

  return treeData;
}


import Vue from 'vue'
import Vuex from 'vuex'
import Menu from "./modules/Menu";
import Public from "./modules/Public";
import LocalCache from "@/utils/cache.js";
import Login from './modules/login'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orgId: '1',
    token: LocalCache.getCache('token'),
    userName:LocalCache.getCache('userName')
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload
    },
    setUserInfo(state, payload){
      state.token = payload.token
      state.userName = payload.userName
    },
    cleraInfo(state){
      LocalCache.deleteCache('token')
      state.token = ''
      state.userName = ''
    }
  },
  actions: {
  },
  modules: {
    Menu,
    Public,
    Login
  },
})

<template>
  <div>
    <div>
      <div class="logo">
        <img src="@/assets/image/logo.png" />
      </div>
    </div>
    <div class="content">
      <div class="login-info">
        <h2>登录</h2>
        <div class="login-data">
          <a-form-model ref="form" :model="form" :rules="rules">
            <a-form-model-item prop="username">
              <a-input placeholder="请输入账号" v-model="form.username">
                <a-icon slot="prefix" type="user" class="icon" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="password">
              <a-input
                placeholder="请输入密码"
                type="password"
                v-model="form.password"
                @keyup.enter="handleLogin"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  class="icon"
                  style="color: #fe500f"
                />
              </a-input>
            </a-form-model-item>
          </a-form-model>
          <div class="data-cate">
            <a-checkbox v-model="remember"> 记住密码 </a-checkbox>
            <!-- <span>忘记密码?</span> -->
          </div>
          <div>
            <a-button
              type="primary"
              @click.self="handleLogin"
              shape="round"
              class="loginbtn"
            >
              登录
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
// import { LoginAPi } from "@/api/login.js";
import LocalCache from "@/utils/cache.js";
// import { tips } from "../../utils/tips";
import { MenuList, menuInfoFilter } from "@/enum/index.js";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      rules: {
        ...rules,
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
      },
      remember: false,
    };
  },
  methods: {
    handleLogin() {
      this.$refs.form.validate(async (isok) => {
        if (isok) {
          if (this.remember) {
            LocalCache.setCache("userData", this.form);
          } else {
            LocalCache.deleteCache("userData");
          }
          let res = await this.$store.dispatch("Login/login", this.form);
          if (res.success) {
            // this.$router.push('/factoryQuery')
            let res = this.$store.state.Login.roleIds;
            let result = menuInfoFilter(MenuList, res);

            if (result.length > 0) {
              // 跳转陆游
              this.$router.push(result[0].path);
            }
          }
        }
      });
    },
  },
  mounted() {
    let data = LocalCache.getCache("userData");
    if (data) {
      this.form = data;
      this.remember = true;
    }
  },
};
</script>

<style scoped lang="scss">
.logo {
  padding: 6px 120px;
  img {
    overflow: hidden;
    margin-top: 20px;
  }
}
.content {
  height: 606px;
  background-image: url("~@/assets/image/login.png");
  padding-right: 169px;
  padding-top: 63px;
  background-size: cover;
  .login-info {
    width: 486px;
    height: 420px;
    background: #fff;
    opacity: 1;
    border-radius: 10px;
    float: right;
    padding: 32px 32px;
    h2 {
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 29px;
      color: #333333;
      cursor: default;
    }
  }
  .login-data {
    padding: 43px 22px 0px;
    .icon {
      font-size: 21px;
      color: #fe500f;
    }
    /deep/ .ant-form-item-control-wrapper {
      input {
        height: 42px;
        padding-left: 40px;
        border-radius: 3px;
        font-size: 16px;
        width: 100%;
      }
      .ant-form-explain {
        position: absolute !important;
      }
    }
    // /deep/.ant-form-item {
    //   margin-bottom: 20px;
    // }
    .ant-form-item:nth-child(1) {
      margin-bottom: 30px;
    }
    .data-cate {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #333333;
      opacity: 0.45;
      margin-top: 28px;
      span {
        cursor: pointer;
      }
    }
    .loginbtn {
      width: 100%;
      margin-top: 48px;
      height: 42px;
      background-image: linear-gradient(to right, #fd8232, #f94838);
      border: none;
      font-size: 20px;
    }
  }
}
</style>

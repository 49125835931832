<template>
  <div :class="{ clearfix: !showImage }">
    <a-upload
      action="http://192.168.233.83:10000/mall-base/mall/v1/oss/upload"
      list-type="picture-card"
      :file-list="datalist"
      @preview="handlePreview"
      :customRequest="customRequest"
      :remove="removeFile"
      v-bind="$attrs"
      accept=".mp4"
      :before-upload="beforeUpload"
    >
      <div>
        <a-icon type="plus" />
        <div class="ant-upload-text">上传视频</div>
      </div>
    </a-upload>
    <div style="position: relative; top: -12px">
      <slot name="tip"> </slot>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <video
        v-if="previewVisible"
        autoplay
        width="100%"
        height="300"
        controls
        id="video"
      >
        您的浏览器不支持播放该视频
      </video>
    </a-modal>
  </div>
</template>
<script>
import { UP_LOAD_PATH } from "@/variable";
import { initObsClient } from "@/utils/obs.js";
export default {
  name: "uplaodVideo",
  props: {
    fileList: {
      content: [Array, String],
      default: () => {
        return [];
      },
    },
    config: {
      type: Object,
      default: () => {
        return {
          maxLength: 1, //张数
          maxSize: 10, //10M
        };
      },
    },
  },
  model: {
    prop: "fileList", //绑定的值，通过父组件传递
    event: "input", //自定义时间名
  },
  data() {
    return {
      previewVisible: false,
      previewImage: "",
      datalist: [],
      defaultData: {
        maxLength: 1, //1张
        maxSize: 10, //10M
      },
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      this.previewVisible = true;
      // 视频预览
      this.$nextTick(() => {
        const video = document.getElementById("video");
        video.src = file.url;
      });
    },
    async customRequest(data) {
      let res = await initObsClient(UP_LOAD_PATH, data.file);
      let obj = {
        uid: +new Date(),
        name: "video.mp4",
        status: "done",
        url: res.fileAddress,
      };
      let target = [...this.datalist, obj];
      this.datalist = target;
      let inputFile = this.datalist.map((v) => v.url);
      this.$emit("input", inputFile);
      this.$emit("blur", target);
    },
    removeFile(data) {
      this.datalist = this.datalist.filter((v) => v.url !== data.url);
      let target = this.datalist.map((v) => v.url);
      this.$emit("input", target);
      this.$emit("blur", target);
    },
    beforeUpload(file) {
      if (this.uploadClose) {
        console.log("adsfadsfasdfadsfasd");
        return false;
      }
      const isJpgOrPng =
        file.name.endsWith(".mp4") || file.name.endsWith(".avi");
      if (!isJpgOrPng) {
        this.$message.error("格式只支持mp4和avi");
      }
      const isLt2M = file.size / 1024 / 1024 < this.defaultData.maxSize;
      if (!isLt2M) {
        this.$message.error(`视频最大为${this.defaultData.maxSize}M`);
      }
      return isJpgOrPng && isLt2M;
    },
  },
  computed: {
    showImage() {
      if (!this.fileList || this.fileList === []) {
        return true;
      }
      return this.fileList?.length < this.defaultData.maxLength;
    },
  },
  mounted() {
    //设置默认值
    Object.assign(this.defaultData, this.config);
    if (this.fileList) {
      setTimeout(() => {
        let target = Array.from(this.fileList);
        target.forEach((item) => {
          let obj = {
            url: item,
            uid: +new Date() + Math.random(),
            name: +new Date() + ".mp4",
            status: "done",
          };
          this.datalist.push(obj);
        });
      });
    }
  },
};
</script>
<style scoped lang="scss">
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-picture-card-wrapper {
  display: table;
  flex-wrap: wrap;
  white-space: nowrap;
  /deep/.ant-upload-list-picture-card {
    flex-wrap: wrap;
  }
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.clearfix {
  /deep/.ant-upload-select-picture-card {
    display: none;
  }
}
</style>

<template>
  <div>
    <div style="text-align: right">
      <a-button @click="addTableRow" v-if="!inputdisabled" class="xp-btn-color">
        新增
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="data" :pagination="false">
      <template slot="inventoryImages" slot-scope="text, record">
        <uploadImage
          :disabled="inputdisabled"
          :config="uploadConfig"
          v-model="record.inventoryImages"
          :key="record.goodsId"
        />
      </template>
      <template slot="inventoryType" slot-scope="text, record">
        <a-input
          v-model="record.inventoryType"
          :disabled="inputdisabled"
          :maxLength="64"
          placeholder="请输入"
        ></a-input>
      </template>

      <template slot="inventoryColor" slot-scope="text, record">
        <a-input
          v-model="record.inventoryColor"
          :maxLength="16"
          :disabled="inputdisabled"
          placeholder="请输入"
        ></a-input>
      </template>
      <template slot="inventoryNum" slot-scope="text, record">
        <div style="display: flex; width: 200px">
          <company-input
            v-model="record.inventoryNumer"
            :config="inventoryConfig"
            :disabled="inputdisabled"
            :isDisabled="confing ? true : false"
            :CompanyNum.sync="record.inventoryConfig"
          />
        </div>
      </template>
      <template slot="handlePrice" slot-scope="text, record">
        <div>
          <a-input-number
            v-model="record.handlePrice"
            :disabled="inputdisabled"
            style="width: 100%"
            :min="0.01"
            :precision="2"
            placeholder="请输入"
          ></a-input-number>
        </div>
      </template>
      <template slot="option" slot-scope="text, record">
        <div
          style="color: #ff4600; cursor: pointer"
          @click="deletatable(record)"
        >
          删除
        </div>
      </template>
      <!-- 自定义表格头 -->
      <span slot="customTitle"><span style="color: red"> * </span> 图片</span>
      <span slot="modelTitle"><span style="color: red"> * </span> 型号</span>
      <span slot="colorTitle"><span style="color: red"> * </span> 颜色</span>
      <span slot="PriceTitle"
        ><span style="color: red"> * </span> 现有库存</span
      >
      <span slot="supplyPriceTitle"
        ><span style="color: red"> * </span> 处理价(元)</span
      >
    </a-table>
  </div>
</template>
  <script>
import CompanyInput from "./component/CompanyInput.vue"
const columns = [
  {
    dataIndex: "inventoryImages",
    key: "inventoryImages",
    slots: { title: "customTitle" },
    scopedSlots: { customRender: "inventoryImages" },
  },
  {
    slots: { title: "modelTitle" },
    scopedSlots: { customRender: "inventoryType" },
    dataIndex: "inventoryType",
    key: "inventoryType",
  },
  {
    slots: { title: "colorTitle" },
    scopedSlots: { customRender: "inventoryColor" },
    dataIndex: "inventoryColor",
    key: "inventoryColor",
  },
  {
    slots: { title: "PriceTitle" },
    scopedSlots: { customRender: "inventoryNum" },
    key: "inventoryNum",
    dataIndex: "inventoryNum",
  },
  {
    slots: { title: "supplyPriceTitle" },
    scopedSlots: { customRender: "handlePrice" },
    key: "handlePrice",
    dataIndex: "handlePrice",
  },
  {
    title: "操作",
    key: "action",
    width: 100,
    scopedSlots: { customRender: "option" },
  },
]

const data = [
  {
    key: "1",
    inventoryType: "",
    inventoryColor: "绿色",
    inventoryNum: "",
    inventoryNumer: "",
    inventoryConfig: '件',
    handlePrice: "",
    inventoryImages: undefined,
  },
]
import uploadImage from "@/components/uploadImage"
import { setKey } from "@/utils/utils.js"
import { SelectionGoods } from "@/api/SelectionGoods.js"
export default {
  name: "skuIdskuIdskuId",
  // inject: ["editData"],
  props: {
    editData: {
      type: Object,
    },
    inventoryInfo: {
      // type: Object,
    },
    confing: {
      type: String,
    }
  },
  data () {
    return {
      data,
      columns,
      uploadConfig: {
        maxLength: 1,
        maxSize: 10,
      },
      inventoryColor: {
        reg: /^.{1,16}$/,
        msg: "请输入1到16位字符",
        type: "String",
      },
      skuModel: {
        reg: /^.{1,64}$/,
        msg: "请输入1到64位字符",
        type: "String",
      },
      numberConfig: {
        reg: /^(([1-9]\d*)(\.\d+)?)$|^0\.\d*[1-9]$/,
        msg: "请输入不包含零的正数",
      },
      inventoryConfig: [
        { value: 1, label: "件" },
        { value: 2, label: "克" },
        { value: 3, label: "千克" },
        { value: 4, label: "米" },
      ],
    }
  },
  components: {
    uploadImage,
    CompanyInput,
  },
  methods: {
    addTableRow () {
      if (this.inputdisabled) return
      let data = {
        key: +new Date(),
        inventoryType: "",
        inventoryColor: "",
        handlePrice: "",
        inventoryNum: "",
        inventoryNumer: "",
        inventoryConfig: this.confing,
        inventoryImages: undefined,
      }
      this.data.push(data)
    },
    deletatable (record) {
      if (this.data.length === 1 || this.inputdisabled) return
      this.data = this.data.filter((v) => v.key !== record.key)
    },
    validateForm () {
      return new Promise((resolve) => {
        let target = this.data.every((v) => {
          return (
            v.inventoryImages.length &&
            v.inventoryType &&
            v.inventoryColor &&
            v.handlePrice &&
            v.inventoryNumer &&
            v.inventoryConfig
          )
        })
        resolve(target)
      })
    },
    handleBlur (data, e) {
      if (isNaN(e.target.getAttribute("aria-valuenow"))) {
        return this.$message.error("值输入错误")
      }
      if (Math.floor(e.target.getAttribute("aria-valuenow")) <= 0) {
        data.handlePrice = 1
        return this.$message.error("值输入最小值为1")
      }
      data.handlePrice = Math.floor(e.target.getAttribute("aria-valuenow"))
    },
    //
    async getlistDetailByName (seartext, dataKey) {
      let res = await SelectionGoods.getlistDetailByName(seartext)
      if (res.success) {
        this[dataKey] = res.data
      }
    },
  },
  watch: {
    inventoryInfo (newVallue) {
      if(this.data.length===1){
        this.data=this.data.filter(item => {
        if (item.inventoryType || item.inventoryColor ||item.inventoryNum ||item.inventoryNumer &&item.inventoryConfig ||item.handlePrice ||item.inventoryImages){
         return item
        }
      })
      }
        this.data.push({
          key: +new Date(),
          inventoryType: newVallue?.skuType,
          inventoryColor: newVallue?.skuColor,
          inventoryNum: "",
          inventoryNumer: "",
          inventoryConfig: this.confing,
          handlePrice: "",
          inventoryImages: newVallue?.skuImages,
        })
    },
    confing (newVallue) {
      this.data.forEach(item => {
        if(!newVallue){
        item.inventoryConfig = '件'
        }else{
          item.inventoryConfig = newVallue
        }
      })
    }

  },
  created () {
    if (this.editData?.data) {
      let target = setKey(this.editData.data.inventoryList, "skuId")
      target = this.editData.data.inventoryList
      target.map(item => {
        item.handlePrice=item.handlePrice===0?null:item.handlePrice
        if(item.inventoryNum==0){
          item.inventoryConfig='件'
          item.inventoryNumer = null        
        }else{
          item.inventoryNum.split(",")
        // 现有库存--单位
        item.inventoryConfig = item.inventoryNum.split(",")[1]
        // 现有库存--数值
        item.inventoryNumer = item.inventoryNum.split(",")[0]
        }
        
      })
      this.data = target
    } else {
      this.data = [
        {
          key: +new Date(),
          inventoryType: "",
          inventoryColor: "",
          inventoryNum: "",
          inventoryNumer: "",
          inventoryConfig: '',
          handlePrice: "",
          inventoryImages: "",
        },
      ]
    }
    this.getlistDetailByName("库存处理起批量单位", "inventoryConfig")
  },
  computed: {
    inputdisabled () {
      return this.editData.type === "uninput"
    },
  },
};
</script>
  <style scoped lang="scss">
/deep/.avatar-uploader > .ant-upload {
  width: 80px;
  height: 80px;
}
/deep/.ant-upload-select-picture-card i {
  font-size: 22px;
}
/deep/.ant-upload-select-picture-card .ant-upload-text {
  font-size: 8px;
}
.ant-table-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  /deep/.ant-table-thead > tr > th {
    background-color: #ffe9db;
  }
}
</style>
  
// 业务管理员
export const MenuList = [
  {
    value: 1,
    data: [
      {
        name: "工厂查询",
        path: "/factoryQuery",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "上架申请",
        path: "/shelfApplication",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "选品上架",
        path: "/selectiongoods",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "评价体系",
        path: "/evaluationSystem",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "工厂评价",
        path: "/evaluationFactory",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "延期申请",
        path: "/extensionApply",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "商户查询",
        path: "/merchantQuery",
        icon: "solution",
        parentId: 2,
      },
      {
        name: "订单审核",
        path: "/orderreview",
        icon: "solution",
        parentId: 2,
      },
      {
        name: "服务付费记录",
        path: "/servicePayment",
        icon: "solution",
        parentId: 2,
      },
      {
        name: "延期审核",
        path: "/extensionExamine",
        icon: "solution",
        parentId: 2,
      },
      {
        name: "广告管理",
        path: "/advertisementManage",
        icon: "team",
        parentId: 3,
      },
      {
        name: "爆品管理",
        path: "/explosiveManage",
        icon: "key",
        parentId: 3,
      },
      {
        name: "新品管理",
        path: "/newManage",
        icon: "key",
        parentId: 3,
      },
      {
        name: "留言管理",
        path: "/leavingManage",
        icon: "key",
        parentId: 3,
      },
      {
        name: "热门工厂",
        path: "/popularFactory",
        icon: "key",
        parentId: 3,
      },
      {
        name: "付费服务",
        path: "/payService",
        icon: "team",
        parentId: 3,
      },
      {
        name: "商户分析",
        path: "/merchantAnalysis",
        icon: "setting",
        parentId: 4,
      },
      {
        name: "选品分析",
        path: "/selectionAnalysis",
        icon: "setting",
        parentId: 4,
      },
      {
        name: "搜索统计",
        path: "/searchStatistics",
        icon: "setting",
        parentId: 4,
      },
      {
        name: "选品分类",
        path: "/classification",
        icon: "setting",
        parentId: 5,
      },
      {
        name: "数据字典",
        path: "/dataDictionary",
        icon: "key",
        parentId: 5,
      },
      {
        name: "用户管理",
        path: "/userManage",
        icon: "key",
        parentId: 5,
      },
      {
        name: "部门管理",
        path: "/departmentManage",
        icon: "key",
        parentId: 5,
      },
    ],
  },
  {
    value: 3,
    data: [
      {
        name: "工厂查询",
        path: "/factoryQuery",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "上架申请",
        path: "/shelfApplication",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "选品上架",
        path: "/selectiongoods",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "评价体系",
        path: "/evaluationSystem",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "订单审核",
        path: "/orderreview",
        icon: "solution",
        parentId: 2,
      },
      {
        name: "延期审核",
        path: "/extensionExamine",
        icon: "solution",
        parentId: 2,
      },
      {
        name: "热门工厂",
        path: "/popularFactory",
        icon: "solution",
        parentId: 2,
      },
      {
        name: "留言管理",
        path: "/leavingManage",
        icon: "key",
        parentId: 3,
      },
      {
        name: "商户分析",
        path: "/merchantAnalysis",
        icon: "setting",
        parentId: 4,
      },
      {
        name: "选品分析",
        path: "/selectionAnalysis",
        icon: "setting",
        parentId: 4,
      },
      {
        name: "搜索统计",
        path: "/searchStatistics",
        icon: "setting",
        parentId: 4,
      },
    ],
  },
  {
    value: 4,
    data: [
      {
        name: "上架申请",
        path: "/shelfApplication",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "选品上架",
        path: "/selectiongoods",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "工厂评价",
        path: "/evaluationFactory",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "延期申请",
        path: "/extensionApply",
        icon: "solution",
        parentId: 1,
      },
      {
        name: "商户查询",
        path: "/merchantQuery",
        icon: "solution",
        parentId: 2,
      },
     
      {
        name: "订单审核",
        path: "/orderreview",
        icon: "solution",
        parentId: 2,
      },
      {
        name: "留言管理",
        path: "/leavingManage",
        icon: "key",
        parentId: 3,
      },
      {
        name: "商户分析",
        path: "/merchantAnalysis",
        icon: "setting",
        parentId: 4,
      },
      {
        name: "选品分析",
        path: "/selectionAnalysis",
        icon: "setting",
        parentId: 4,
      },
    ],
  },
  {
    value: 5,
    data: [
      {
        name: "广告管理",
        path: "/advertisementManage",
        icon: "team",
        parentId: 3,
      },
      {
        name: "爆品管理",
        path: "/explosiveManage",
        icon: "key",
        parentId: 3,
      },
      {
        name: "新品管理",
        path: "/newManage",
        icon: "key",
        parentId: 3,
      },
    ],
  },
];

export const newMenuList = [
  {
    name: "工厂管理",
    icon: "gc",
    pre: 0, // 前面有几个
    id: 1,
    children: [],
  },
  {
    name: "商户管理",
    icon: "sh",
    id: 2,
    children: [],
  },
  {
    name: "门户管理",
    icon: "mh",
    id: 3,
    children: [],
  },
  {
    name: "统计分析",
    icon: "tj",
    id: 4,
    children: [],
  },
  {
    name: "系统管理",
    icon: "xt",
    id: 5,
    children: [],
  },
];

// const roleIds = [1];

// 处理菜单信息
export function menuInfoFilter(sideMenuList, roleIds) {
  console.log(roleIds);
  if (roleIds === [] || !roleIds) {
    return;
  }
  // console.log(sideMenuList, roleIds);
  let arr = [];
  sideMenuList.forEach((item) => {
    if (roleIds.includes(item.value)) {
      arr = [...arr, ...item.data];
    }
  });
  let result = unique(arr);
  return result;
}

// 去重
function unique(arr) {
  // console.log("dfsafdas");
  let obj = {};
  arr = arr.reduce((newArr, next) => {
    obj[next.name] ? "" : (obj[next.name] = true && newArr.push(next));
    return newArr;
  }, []);
  return arr;
}

// 动态添加父节点下面的菜单
export function addMenuList(arr, menuListArr) {
  // console.log(arr, menuListArr);

  for (let i = 0; i < arr.length; i++) {
    menuListArr[arr[i].parentId - 1].children.push(arr[i]);
  }

  let res = menuListArr.filter((item) => item.children.length > 0);

  // console.log(res, res.length);
  return res;
}


const Colors = {
  Orange: "#FF4600",
  Gray: "#999999",
  LightYellow: "#FAAD14",
  Green: "#52C41A ",
  Red: "#FF4D4F",
};

export const StatusColors = {
  主题: Colors.Orange,
  过期: Colors.Orange,
  正常: Colors.Gray,
  未认证: Colors.Gray,
  封存: Colors.Gray,
  审核: Colors.Orange,
  查看: Colors.Orange,
  待审核: Colors.LightYellow,
  审核中: Colors.LightYellow,
  草稿: Colors.LightYellow,
  已认证: Colors.Green,
  已下架: Colors.Green,
  已禁用: Colors.Red,
  已退回: Colors.Gray,
  通过: Colors.Green,
  生效: Colors.Green,
  已上架: Colors.Red,
};

export const getColor = (status) => {
  if (StatusColors.status !== "") {
    return StatusColors[status];
  } else {
    return Colors.Gray;
  }
};
